import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import AddStrip from "./Add/Add";

export const Stripepayment = () => {
  storage.setPageTitle("Business Seller");
  return (
    <Routes>
      <Route path="/" element={<AddStrip />} />
    </Routes>
  );
};
