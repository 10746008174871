import React from "react";
import { MenuItem, Select } from "@mui/material";

const label = { inputProps: { "aria-label": "Switch demo" } };

export const SelectCell = ({ id, selectedRowId, onSelectRow, value, className }) => {
  const handleChange = (event) => {
    const newValue = event.target.value;
    onSelectRow(id, newValue); // Update the selected row and value
  };
  return (
    <Select
      className={className} 
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={value}
      label="Age"
      onChange={handleChange}
    >
      <MenuItem value="open">Open</MenuItem>
      <MenuItem value="inprocess">Inprocess</MenuItem>
    </Select>
  );
};
