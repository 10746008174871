import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CustomSelect } from "../../../components/common/CustomSelect";
import { Formik, useFormikContext } from "formik";
import InputText from "../../../components/common/InputText";
import {
  addFranchiseSchema,
  addSellerSchema,
} from "../../../validations/Franchise";
import { toast } from "react-toastify";
import {
  AddEntitiyMethod,
  getAllCategory,
  getAllCity,
  getAllCountry,
  getAllState,
  yearData,
  getSubscription,
  AddPaymentEntitiyMethod,
  handleSelectAndPayClick
} from "../../../helpers/helpersMethods";
import Loader from "../../../components/common/Loader";
import useDynamicFields from "../../../components/common/useDynamicFields";
import { uploadMediaSeller } from "../../../services/userService";
import { addFranchise, addSeller } from "../../../services/franchiseService";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { FaTimes } from "react-icons/fa";
import CustomRadioHorizontal from "../../../components/common/CustomRadioHorizontal";
import FormDatesPicker from "../../../components/common/FormDatesPicker";
import Datepicker from "../../../components/common/Datepicker";
import PaymentModal from "../../../components/common/PaymentModal";

const MAX_MEDIA_ALLOWED = 5; // Maximum number of images allowed
const MAX_VIDEO_ALLOWED = 1; // Maximum number of images allowed
const MAX_IMAGE_SIZE_IN_BYTES = 10 * 1024 * 1024; // 500KB in bytes
const MAX_DOCUMENT_SIZE_IN_BYTES = 10 * 1024 * 1024; // 500KB in bytes
const MAX_VIDEO_SIZE_IN_BYTES = 50 * 1024 * 1024; // 50MB in bytes
const ALLOWED_VIDEO_TYPES = ["video/mp4"];
const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];
export const AddSeller = () => {
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState();
  const [selectedImagesMedia, setSelectedImagesMedia] = useState([]);
  const fileInputRef = useRef(null);
  const fileVideoInputRef = useRef(null);
  const fileInputRefAgge = useRef(null);
  const fileInputRefLease = useRef(null);
  const fileInputRefPl = useRef(null);
  const formik = useFormikContext();
  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [expenseList, setExpenseList] = useState([
    { id: "Employees", name: "Employees" },
    { id: "Rent", name: "Rent" },
    { id: "Equipment", name: "Equipment" },
  ]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [agreementUpload, setAgreementUpload] = useState("");
  const [selectedDate, setSelectedDate] = useState();
  const [yearOptions, setYearOptions] = useState(yearData());
  const [show, setShow] = useState(false);
  const [subscription, setSubscription] = useState("");
  const radioData = [
    { label: "Yes", value: "yes" },
    { label: "No", value: "no" },
  ];
  const ContactData = [
    { label: "Phone Number", value: "phone" },
    { label: "Email Address", value: "email" },
  ];
  useEffect(() => {
    const fetchData = async () => {
      try {
        const allCountryData = await getAllCountry();
        setCountryList(allCountryData);
      } catch (error) {
        // Handle any errors that occurred during the data retrieval
        console.error("Error fetching data:", error);
      }
    };
    const industryData = async () => {
      try {
        const allIndustryData = await getAllCategory("industry");
        setIndustryList(allIndustryData);
      } catch (error) {
        // Handle any errors that occurred during the data retrieval
        console.error("Error fetching data:", error);
      }
    };
    const subscriptionData = async () => {
      try {
        const subscriptionDetails = await getSubscription();
        setSubscription(
          subscriptionDetails ? subscriptionDetails.seller : ""
        );
        console.log(subscriptionDetails);
      } catch (error) {
        // Handle any errors that occurred during the data retrieval
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
    industryData();
    subscriptionData();
  }, []);

  const handleCountryChange = async (countryId) => {
    setLoading(true);
    try {
      const allStateData = await getAllState(countryId);
      setStateList(allStateData);
      setCityList([]);
      setLoading(false);
    } catch (error) {
      // Handle any errors that occurred during the data retrieval
      setLoading(false);
      console.error("Error fetching state data:", error);
    }
  };
  const handleStateChange = async (stateId) => {
    setLoading(true);
    try {
      const allCityData = await getAllCity(stateId);
      setCityList(allCityData);
      setLoading(false);
    } catch (error) {
      // Handle any errors that occurred during the data retrieval
      setLoading(false);
      console.error("Error fetching state data:", error);
    }
  };

  const handleBrowseClick = () => {
    // Programmatically trigger the file input click event
    fileInputRef.current.click();
  };
  const handleVideoBrowseClick = () => {
    // Programmatically trigger the file input click event
    fileVideoInputRef.current.click();
  };

  const handleImageChange = async (event, setFieldValue, values, type) => {
    const { target } = event;
    const { files } = target;
    const imageFile = files[0];

    if (!imageFile) {
      toast.error("Please select an image.");
      return;
    }

    // Validate the image type and size
    if (imageFile.size > MAX_IMAGE_SIZE_IN_BYTES) {
      toast.error("The image size exceeds the maximum limit of 10MB.");
      target.value = ""; // Clear the selected file
      return;
    }

    const acceptedTypes = [".jpeg", ".jpg", ".png"];
    const fileType = imageFile.name
      .substring(imageFile.name.lastIndexOf("."))
      .toLowerCase();
    if (!acceptedTypes.includes(fileType)) {
      toast.error("Only JPEG and PNG images are allowed.");
      target.value = ""; // Clear the selected file
      return;
    }
    const formData = new FormData();
    formData.append("file", imageFile);
    setSelectedImages("");
    setLoading(true);
    uploadMediaSeller(formData).then(
      (response) => {
        const res = response?.data;
        setTimeout(() => {
          setLoading(false);
          if (response?.statusCode === 200) {
            setFieldValue(type, res.file_name);
            setSelectedImages({
              file_name: res.file_name,
              file_path: res.file_path,
            });
          }
          if (res) {
          }
        }, 1000);
      },
      (error) => {
        setLoading(false);
        // toast.error(error.response.data.msg);
      }
    );
    target.value = "";
    console.log(selectedImages);
  };
  const handleDocumentUpload = async (event, setFieldValue, values, type) => {
    const { target } = event;
    const { files } = target;
    const documentFile = files[0];

    if (!documentFile) {
      toast.error("Please select an file.");
      return;
    }

    // Validate the image type and size
    if (documentFile.size > MAX_DOCUMENT_SIZE_IN_BYTES) {
      toast.error("File size is too large, maximum file size is 10MB");
      target.value = ""; // Clear the selected file
      return;
    }
    const acceptedTypes = [".pdf", ".doc", ".docx"];
    const fileType = documentFile.name
      .substring(documentFile.name.lastIndexOf("."))
      .toLowerCase();
    if (!acceptedTypes.includes(fileType)) {
      toast.error("Only pdf and docx file are allowed.");
      target.value = ""; // Clear the selected file
      return;
    }
    const formData = new FormData();
    formData.append("file", documentFile);
    setLoading(true);
    uploadMediaSeller(formData).then(
      (response) => {
        const res = response?.data;
        setTimeout(() => {
          setLoading(false);
          if (response?.statusCode === 200) {
            setFieldValue(type, res.file_name);
          }
        }, 1000);
      },
      (error) => {
        setLoading(false);
        // toast.error(error.response.data.msg);
      }
    );
    target.value = "";
  };

  const handleMediaChange = async (event, handleChange, values) => {
    const { target } = event;
    const { files } = target;
    const mediaFile = files[0];
    let mediaType = "image";
    if (!mediaFile) {
      toast.error("Please select an media.");
      return;
    }

    // Validate the media type and size
    if (mediaFile.type === "video/mp4") {
      mediaType = "video";
      // Handling video file
      if (mediaFile.size > 50 * 1024 * 1024) {
        toast.error("The video size exceeds the maximum limit of 50MB.");
        target.value = ""; // Clear the selected file
        return;
      }
      if (selectedImagesMedia.some((media) => media.media_type === "video")) {
        toast.error("You can only upload one video.");
        target.value = ""; // Clear the selected file
        return;
      }
    } else if (mediaFile.type.startsWith("image/")) {
      // Handling image file
      if (mediaFile.size > 10 * 1024 * 1024) {
        toast.error("The image size exceeds the maximum limit of 10MB.");
        target.value = ""; // Clear the selected file
        return;
      }
    } else {
      toast.error("Only MP4 videos and images (JPEG, JPG, PNG) are allowed.");
      target.value = ""; // Clear the selected file
      return;
    }

    if (selectedImagesMedia.length >= 5) {
      toast.error("You can only upload up to 5 media files.");
      target.value = ""; // Clear the selected file
      return;
    }
    const formData = new FormData();
    formData.append("file", mediaFile);
    formData.append("file_type", mediaType);
    setLoading(true);
    uploadMediaSeller(formData)
      .then((response) => {
        const res = response?.data;
        setTimeout(() => {
          setLoading(false);
          if (response?.statusCode === 200) {
            setSelectedImagesMedia((prevSelectedImages) => [
              ...prevSelectedImages,
              {
                media_file: res.file_name,
                media_file_name: res.file_path,
                media_type: mediaType,
              },
            ]);
            console.log(selectedImagesMedia);
          }
          if (res) {
            // Handle additional logic if needed
          }
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        // Handle error if needed
        // toast.error(error.response.data.msg);
      });

    target.value = "";
  };

  const getSelectedImageNames = (values) => {
    //values.logo = "";
    return (
      <div className="thumb">
        {selectedImages && (
          <>
            <div className="thumbInner">
              <img src={selectedImages.file_path} />
              {/* <span>{image.file.name}</span> */}
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveImage(values)}
              >
                <FaTimes size={9} color="#fff" />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };
  const getSelectedVideoNames = () => {
    return selectedImagesMedia.map((media, index) => (
      <div key={index} className="thumb">
        {media.media_file_name && (
          <>
            <div className="thumbInner">
              {media.media_type == "image" ? (
                <img src={media.media_file_name} alt={`Thumbnail ${index}`} />
              ) : (
                <video controls>
                  <source src={media.media_file_name} type="video/mp4" />
                </video>
              )}
              {/* <span>{video.file.name}</span> */}
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveVideo(index)}
              >
                <FaTimes size={9} color="#fff" />
              </Button>
            </div>
          </>
        )}
      </div>
    ));
  };

  const handleRemoveImage = (values) => {
    setSelectedImages("");
  };
  const handleRemoveVideo = (index) => {
    setSelectedImagesMedia((prevSelectedVideos) => {
      // Create a new array and exclude the video at the specified index
      const updatedVideos = prevSelectedVideos.filter((_, i) => i !== index);
      return updatedVideos;
    });
  };

  const fieldsDocumentConfig = [
    {
      type: "file",
      label: "Document",
      name: "document_file_name",
      validation: (value) => {
        if (!value) {
          return "Please select file";
        }
        return ""; // Empty string means validation passed
      },
      isRequired: true,
      acceptedFileTypes: ".pdf,.doc,.docx",
      maxFileSize: 5 * 1024 * 1024, // 5MB in bytes
    },
    {
      type: "text",
      label: "Document Name",
      name: "document_name",
      validation: (value) => {
        if (!value) {
          return "Document Name is required.";
        }
        return ""; // Empty string means validation passed
      },
      isRequired: true,
    },
  ];

  const {
    renderDynamicFields: renderUploadFields,
    dynamicFields: documentUploadFields,
  } = useDynamicFields(
    fieldsDocumentConfig,
    10,
    [{}],
    isFormSubmitted,
    setLoading,
    "add",
    "seller"
  );

  const handleSubmit = (values, actions, setErrors) => {
    setFormSubmitted(true);
    if (!values.lease_upload && !values.year_left_lease) {
      setErrors({
        year_left_lease: "Field is required when Lease is empty.",
      });
      return;
    }

    if (values.contact_prefrence == "phone" && !values.contact_phone) {
      setErrors({
        contact_phone: "Field is Required..",
      });
      return;
    }
    if (values.contact_prefrence == "email" && !values.contact_email) {
      setErrors({
        contact_email: "Field is Required..",
      });
      return;
    }
    const hasEmptyDynamicFieldsDocumentFields = documentUploadFields.some(
      (field) => {
        // Modify the condition based on your validation logic
        return (
          (field.document_file_name && !field.document_name) ||
          (!field.document_file_name && field.document_name)
        );
      }
    );
    if (hasEmptyDynamicFieldsDocumentFields) {
      toast.error("Please fill in all the document category fields.");
      actions.setSubmitting(false);
      return;
    }
    if (!selectedImages) {
      toast.error("Please select logo image.");
      return;
    }
    const mergedValues = {
      document_name_json: [...documentUploadFields],
      logo: selectedImages ? selectedImages.file_name : "",
      media_json: [...selectedImagesMedia],
      ...values, // Assuming the form's static fields are present in the 'values' object
    };
    mergedValues.media_json = JSON.stringify(mergedValues.media_json);
    mergedValues.document_name_json = JSON.stringify(
      mergedValues.document_name_json
    );
    delete mergedValues.documentName;
    actions.setSubmitting(false);

    if (mergedValues) {
      AddPaymentEntitiyMethod(
        setLoading,
        addSeller,
        mergedValues,
        "",
        nameBasedProtectedRoutes.seller.path,
        navigate,
        setShow,
        "seller",
        "2"
      );
    }
  };
  const handleClose = () => {
    setShow(false);
    navigate(nameBasedProtectedRoutes.seller.path);
  };
  return (
    <>
      {industryList ? (
        <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
          <div className="flex-grow-1 pageContent position-relative pt-4">
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <svg width="29.4" height="26.46" viewBox="0 0 29.4 26.46">
                        <path
                          id="Icon_material-business-center"
                          data-name="Icon material-business-center"
                          d="M14.76,23.61V22.14H4.485L4.47,28.02a2.93,2.93,0,0,0,2.94,2.94H27.99a2.93,2.93,0,0,0,2.94-2.94V22.14H20.64v1.47Zm14.7-13.23H23.566V7.44L20.626,4.5h-5.88l-2.94,2.94v2.94H5.94A2.949,2.949,0,0,0,3,13.32v4.41a2.93,2.93,0,0,0,2.94,2.94h8.82V17.73h5.88v2.94h8.82a2.949,2.949,0,0,0,2.94-2.94V13.32A2.949,2.949,0,0,0,29.46,10.38Zm-8.82,0H14.76V7.44h5.88Z"
                          transform="translate(-3 -4.5)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    Business Seller Add
                  </h5>
                </Stack>
              </div>
              <Formik
                validationSchema={addSellerSchema}
                initialValues={{
                  owner_name: "",
                  name: "",
                  indus_category_id: "",
                  address: "",
                  city_id: "",
                  state_id: "",
                  country_id: "",
                  date_opened: "",
                  documentName: "",
                  agreement_upload: "",
                  year_left_lease: "",
                  lease_upload: "",
                  year_in_business: "",
                  annual_revenue: "",
                  cost_of_business: "",
                  p_n_l_upload: "",
                  asking_price: "",
                  biggest_expense: "",
                  is_price_negotiable: "yes",
                  contact_prefrence: "phone",
                  contact_email: "",
                  contact_phone: "",
                  logo: "",
                  description: "",
                }}
                onSubmit={(values, actions) => {
                  console.log("xcc");
                  actions.setSubmitting(false);
                  setFormSubmitted(true);
                  handleSubmit(values, actions, actions.setErrors);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex-grow-1 d-flex flex-column px-1">
                      <div className="p-3 d-flex flex-column h-100">
                        <div className="d-flex flex-column flex-grow-1 theme-from">
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="owner_name"
                                label="Owner Name *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.owner_name}
                                touched={touched.owner_name}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.owner_name}
                                name="name"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="name"
                                label="Business Name *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.name}
                                touched={touched.name}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.name}
                                name="name"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={12} md={12} lg={10} className="pb-1">
                              <InputText
                                controlId="description"
                                label="Description *"
                                type="textarea"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.description}
                                touched={touched.description}
                                className="w-100 fs-16 mb-4 custom-text-field customTextarea"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.description}
                                multiline
                                name="description"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={12} md={12} lg={10} className="pb-1">
                              <InputText
                                controlId="address"
                                label="Address *"
                                type="textarea"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.address}
                                touched={touched.address}
                                className="w-100 fs-16 mb-4 custom-text-field customTextarea"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                multiline
                                value={values.address}
                                name="address"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                controlId="country_id"
                                label="Country *"
                                placeholder="Select Country"
                                options={countryList}
                                touched={touched.country_id}
                                handleBlur={handleBlur}
                                handleChange={(event) => {
                                  const selectedValue = event.target.value;
                                  values.state_id = "";
                                  values.city_id = "";
                                  setSelectedValue(selectedValue); // Set the selected value (not necessary if not used elsewhere)
                                  handleCountryChange(selectedValue); // Call handleStateChange with the selected country ID
                                  setFieldValue("country_id", selectedValue);
                                }}
                                errorsField={errors.country_id}
                                value={values.country_id}
                                name="country_id"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                controlId="state_id"
                                label="State *"
                                placeholder="Select State"
                                options={stateList}
                                touched={touched.state_id}
                                handleBlur={handleBlur}
                                errorsField={errors.state_id}
                                value={values.state_id}
                                handleChange={(event) => {
                                  const selectedValue = event.target.value;
                                  setSelectedValue(selectedValue); // Set the selected value (not necessary if not used elsewhere)
                                  handleStateChange(selectedValue); // Call handleStateChange with the selected country ID
                                  setFieldValue("state_id", selectedValue);
                                }}
                                name="state_id"
                              />
                            </Col>
                          </Row>

                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                label="City *"
                                placeholder="Select City"
                                options={cityList}
                                touched={touched.city_id}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.city_id}
                                value={values.city_id}
                                name="city_id"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="customDatePickerMain">
                                <FormDatesPicker
                                  controlId="date_opened"
                                  name="date_opened"
                                  placeholder="Date Opened *"
                                  label="Date opened *"
                                  selected={values.date_opened}
                                  onBlur={handleBlur}
                                  error={errors.date_opened}
                                  touched={touched.date_opened}
                                  handleChange={handleChange}
                                  setFieldValue={setFieldValue}
                                  errorsField={errors.date_opened}
                                  leftColSmall={true}
                                  allowPastDates={true}
                                  allowFutureDates={false}
                                  value={
                                    values.date_opened ? values.date_opened : ""
                                  }
                                />
                                {/* <Datepicker  
                                      id="date-filter"
                                      name="Date"
                                      date={selectedDate}
                                      variant="standard"
                                      handleDateChange={(value) => setSelectedDate(value) }
                                      value={selectedDate}
                                      placeholder="Select date"
                                  /> */}
                              </div>
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="position-relative customUpload">
                                <input
                                  ref={fileInputRefAgge}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleDocumentUpload(
                                      event,
                                      setFieldValue,
                                      values,
                                      "agreement_upload"
                                    )
                                  }
                                />
                                <InputText
                                  controlId="agreement_upload"
                                  label="Franchise Agreement Upload"
                                  className="w-100 fs-16 mb-4 custom-text-field"
                                  InputLabelProps={{
                                    className: "fs-15 grey-color",
                                  }}
                                  name="agreement_upload"
                                  type="text"
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  errorsField={errors.agreement_upload}
                                  touched={touched.agreement_upload}
                                  value={values.agreement_upload}
                                  disabled="disabled"
                                />
                                <button
                                  className="mw-84 fs-12 fw-medium text-black position-absolute"
                                  onClick={() => {
                                    fileInputRefAgge.current.click();
                                  }}
                                  type="button"
                                >
                                  Browse
                                </button>
                              </div>
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="position-relative customUpload">
                                <input
                                  ref={fileInputRefLease}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleDocumentUpload(
                                      event,
                                      setFieldValue,
                                      values,
                                      "lease_upload"
                                    )
                                  }
                                />
                                <InputText
                                  controlId="lease_upload"
                                  label="Lease Upload"
                                  className="w-100 fs-16 mb-4 custom-text-field"
                                  InputLabelProps={{
                                    className: "fs-15 grey-color",
                                  }}
                                  name="lease_upload"
                                  type="text"
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  errorsField={errors.lease_upload}
                                  touched={touched.lease_upload}
                                  value={values.lease_upload}
                                  disabled="disabled"
                                />
                                <button
                                  className="mw-84 fs-12 fw-medium text-black position-absolute"
                                  onClick={() => {
                                    fileInputRefLease.current.click();
                                  }}
                                  type="button"
                                >
                                  Browse
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="year_left_lease"
                                label="Year Left On Lease"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.year_left_lease}
                                touched={touched.year_left_lease}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.year_left_lease}
                                name="year_left_lease"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="annual_revenue"
                                label="Average Gross Revenue *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.annual_revenue}
                                touched={touched.annual_revenue}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.annual_revenue}
                                name="annual_revenue"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="cost_of_business"
                                label="Cost Of Doing business *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.cost_of_business}
                                touched={touched.cost_of_business}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.cost_of_business}
                                name="cost_of_business"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="position-relative customUpload">
                                <input
                                  ref={fileInputRefPl}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleDocumentUpload(
                                      event,
                                      setFieldValue,
                                      values,
                                      "p_n_l_upload"
                                    )
                                  }
                                />
                                <InputText
                                  controlId="p_n_l_upload"
                                  label="P&L Upload"
                                  className="w-100 fs-16 mb-4 custom-text-field"
                                  InputLabelProps={{
                                    className: "fs-15 grey-color",
                                  }}
                                  name="p_n_l_upload"
                                  type="text"
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  errorsField={errors.p_n_l_upload}
                                  touched={touched.p_n_l_upload}
                                  value={values.p_n_l_upload}
                                  disabled="disabled"
                                />
                                <button
                                  className="mw-84 fs-12 fw-medium text-black position-absolute"
                                  onClick={() => {
                                    fileInputRefPl.current.click();
                                  }}
                                  type="button"
                                >
                                  Browse
                                </button>
                              </div>
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            {/* <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                label="Year in Business *"
                                placeholder="Select Year"
                                options={yearOptions}
                                touched={touched.year_in_business}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.year_in_business}
                                value={values.year_in_business}
                                name="year_in_business"
                              />
                            </Col> */}
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="asking_price"
                                label="Asking Price *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.asking_price}
                                touched={touched.asking_price}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.asking_price}
                                name="asking_price"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                controlId="biggest_expense"
                                label="Biggest Expense *"
                                placeholder="Select Biggest Expense"
                                options={expenseList}
                                touched={touched.biggest_expense}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.biggest_expense}
                                value={values.biggest_expense}
                                name="biggest_expense"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                controlId="indus_category_id"
                                label="Industry Type *"
                                placeholder="Select industry"
                                options={industryList}
                                touched={touched.indus_category_id}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.indus_category_id}
                                value={values.indus_category_id}
                                name="indus_category_id"
                              />
                            </Col>
                            
                          </Row>
                          <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="custoRadioMain">
                                <CustomRadioHorizontal
                                  leftColSmall={true}
                                  inline={true}
                                  label="Negotiable Price"
                                  name="is_price_negotiable"
                                  touched={touched.status}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.is_price_negotiable}
                                  value={values.is_price_negotiable}
                                  data={radioData}
                                />
                              </div>
                            </Col>
                          </Row>
                          <h5 className="mb-0 mt-4 fw-medium fs-18 text-black">
                            Contact Information
                          </h5>
                          <Row className="gx-xl-5">
                         
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="contact_phone"
                                label="Phone Number"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.contact_phone}
                                touched={touched.contact_phone}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.contact_phone}
                                name="contact_phone"
                              />
                            </Col>

                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="contact_email"
                                label="Email Address"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.contact_email}
                                touched={touched.contact_email}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.contact_email}
                                name="contact_email"
                              />
                            </Col>

                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="custoRadioMain no-margin">
                                <CustomRadioHorizontal
                                  leftColSmall={true}
                                  inline={true}
                                  label="Contact Prefrence"
                                  name="contact_prefrence"
                                  touched={touched.status}
                                  handleBlur={handleBlur}
                                  handleChange={handleChange}
                                  errorsField={errors.contact_prefrence}
                                  value={values.contact_prefrence}
                                  data={ContactData}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="position-relative customUpload">
                                <input
                                  ref={fileInputRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleImageChange(
                                      event,
                                      setFieldValue,
                                      values,
                                      "logo"
                                    )
                                  }
                                />
                                <InputText
                                  controlId="logo"
                                  label="Upload Logo *"
                                  className="w-100 fs-16 mb-4 custom-text-field"
                                  InputLabelProps={{
                                    className: "fs-15 grey-color",
                                  }}
                                  value={selectedImages ? values.logo : ""}
                                  name="logo"
                                  errorsField={errors.logo}
                                  touched={touched.logo}
                                  type="text"
                                  disabled="disabled"
                                />
                                <button
                                  className="mw-84 fs-12 fw-medium text-black position-absolute"
                                  onClick={handleBrowseClick}
                                  type="button"
                                >
                                  Browse
                                </button>
                                {getSelectedImageNames(values)}
                                <div className="thumb"></div>
                              </div>
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="position-relative customUpload">
                                <input
                                  ref={fileVideoInputRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleMediaChange(
                                      event,
                                      handleChange,
                                      values
                                    )
                                  }
                                />
                                <InputText
                                  controlId="uploadVideo"
                                  label="Upload Media"
                                  className="w-100 fs-16 mb-4 custom-text-field"
                                  InputLabelProps={{
                                    className: "fs-15 grey-color",
                                  }}
                                  value={values.uploadVideo}
                                  name="uploadVideo"
                                  type="text"
                                  disabled="disabled"
                                />
                                <button
                                  className="mw-84 fs-12 fw-medium text-black position-absolute"
                                  onClick={handleVideoBrowseClick}
                                  type="button"
                                >
                                  Browse
                                </button>
                                <div>{getSelectedVideoNames()}</div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1"></Col>
                          </Row>

                          <h5 className="mb-0 mt-4 fw-medium fs-18 text-black">
                            Upload Document Category
                          </h5>
                          <div>{renderUploadFields()}</div>
                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="my-3"
                          >
                            <Button
                              variant="primary"
                              className="rounded-lg fs-15 mw-84"
                              type="submit"
                            >
                              Save
                            </Button>
                            <Link
                              to={nameBasedProtectedRoutes.seller.path}
                              className="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn"
                            >
                              Cancel
                            </Link>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}
      <PaymentModal
        show={show}
        handleClose={handleClose}
        details={subscription}
        navigate={navigate}
        handleSelectAndPayClick={handleSelectAndPayClick}
      />
      <Loader isLoading={loading} />
    </>
  );
};
