import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AddSeller } from "./Add/Add";
import { EditSeller } from "./Edit/Edit";
import { AllSeller } from "./List/List";
import { View } from "./View/View";

export const Seller = () => {
  storage.setPageTitle("Business Seller");
  return (
    <Routes>
      <Route path="/" element={<AllSeller />} />
      <Route path="/add" element={<AddSeller />} />
      <Route path="/edit/:id" element={<EditSeller />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};
