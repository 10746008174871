import { Field } from "formik";
import { Col, Row, Stack } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const CustomRadioHorizontal = ({
  label,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  data,
  name,
  inline,
  setColunView,
  leftColSmall,
}) => {
  return (
    <Form.Group className="theme-form-horizontal pb-3">
      <Row>
        <Col
          sm="auto"
          className={`left-col ${leftColSmall ? "left-col-sm" : ""}`}
        >
          {label ? (
            <Stack
              direction="horizontal"
              gap={2}
              className="justify-content-sm-between py-sm-1"
            >
              <Form.Label className="lh-sm">{label}</Form.Label>
              {setColunView ? <span className="colun-content">:</span> : null}
            </Stack>
          ) : null}
        </Col>
        <Col sm>
          <div className="position-relative">
            {data.map((option) => (
              <Field key={option.value} name={name}>
                {({ field, form }) => (
                  <Form.Check
                    inline={inline}
                    type="radio"
                    className="me-sm-4 pe-1 mb-2 user-select-none theme-radio"
                    id={`${name}-${option.value}`}
                    label={option.label}
                    value={option.value}
                    checked={field.value === option.value}
                    onChange={handleChange}
                    isInvalid={false}
                    feedback={form.errors[name]}
                    name={name}
                  />
                )}
              </Field>
            ))}
            <Form.Control.Feedback
              type="invalid"
              className={`${!!errorsField && touched ? "d-block" : null}`}
            >
              {errorsField}
            </Form.Control.Feedback>
          </div>
        </Col>
      </Row>
    </Form.Group>
  );
};

export default CustomRadioHorizontal;
