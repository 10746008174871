import React from "react";
import { Button, OverlayTrigger, Stack, Tooltip } from "react-bootstrap";
import { MdRemoveRedEye, MdModeEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { HiTrash } from "react-icons/hi";

export const ActionCell = ({
  controlId,
  isViewEnable,
  isEditEnable,
  isDeleteEnable,
  isLogInEnable,
  redirectID,
  disabled,
  rowData,
  handleDeleteClick,
}) => {
  const navigate = useNavigate();
  // const GoToList = () => {
  //   navigate(`/${controlId}`, {
  //     state: rowData,
  //   });
  // };
  const GoToView = () => {
    navigate(`/${controlId}/view/${redirectID}`, {
      state: rowData,
    });
  };
  const GoToEdit = () => {
    navigate(`/${controlId}/edit/${redirectID}`, {
      state: rowData,
    });
  };
  return (
    <Stack direction="horizontal" gap={2} className="justify-content-center">
      {isViewEnable ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="view-tooltip">View</Tooltip>}
        >
          <Button onClick={GoToView} variant="link" className="p-0 text-light">
            <MdRemoveRedEye size={20} />
          </Button>
        </OverlayTrigger>
      ) : null}

      {isEditEnable ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
        >
          <Button
            onClick={GoToEdit}
            variant="link"
            disabled={disabled}
            className="p-0 text-light"
          >
            <MdModeEdit size={18} />
          </Button>
        </OverlayTrigger>
      ) : null}
      {isLogInEnable ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="edit-tooltip">Edit</Tooltip>}
        >
          <Button
            onClick={GoToEdit}
            variant="link"
            disabled={disabled}
            className="p-0 text-light"
          >
            <MdModeEdit size={18} />
          </Button>
        </OverlayTrigger>
      ) : null}
      {isDeleteEnable ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="delete-tooltip">Delete</Tooltip>}
        >
          <Button
            onClick={handleDeleteClick}
            variant="link"
            className="p-0 text-light"
          >
            <HiTrash size={18} />
          </Button>
        </OverlayTrigger>
      ) : null}
    </Stack>
  );
};
