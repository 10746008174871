import { ForgetPassword } from "../pages/Auth/ForgetPassword";
import { Login } from "../pages/Auth/Login";
import { ResetPassword } from "../pages/Auth/ResetPassword";
import { Register } from "../pages/Auth/Register";
import { UserVerify } from "../pages/Auth/UserVerify";
import { PrivacyPolicy } from "../pages/Cms/PrivacyPolicy";
import { TermAndCondition } from "../pages/Cms/TermAndCondition";
import { LandingPage } from "../pages/LandingPage/Landing";
// import { MultifactorAuth } from "../pages/Auth/MultifactorAuth";


/**
 * Public Routes Available in App
 * @type {{}}
 */
export const publicRoutes = [
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "login",
    element: <Login />,
  },
//   {
//     path: "verification",
//     element: <MultifactorAuth />,
//   },
  {
    path: "forgot-password",
    element: <ForgetPassword />,
  },
  {
    path: "reset-password/:token?",
    element: <ResetPassword />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "user-verify",
    element: <UserVerify />,
  },
  {
    path: "privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "term-and-condition",
    element: <TermAndCondition />,
  },
  {
    path: "landing-page",
    element: <LandingPage />,
  },
];
export const nameBasedRoutes = {
  home: {
    name: "Home",
    path: `/`,
  },
  login: {
    name: "Login",
    path: `/login`,
  },
  forgotPassword: {
    name: "Forgot Passowrd",
    path: `/forgot-password`,
  },
  resetPassword: {
    name: "Reset Passowrd",
    path: `/reset-password`,
  },
  register: {
    name: "Register",
    path: `/register`,
  },
  privacyPolicy: {
    name: "Privacy Policy",
    path: `/privacy-policy`,
  },
  termAndCondition: {
    name: "Term And Condition",
    path: `/term-and-condition`,
  },
}
