import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AllSubscription } from "./List/List";

export const MyAccount = () => {
  storage.setPageTitle("My Account");
  return (
    <Routes>
      <Route path="/" element={<AllSubscription />} />
    </Routes>
  );
};
