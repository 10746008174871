import Axios from "axios";
import storage from "../helpers/storage";

/**
 * Axios Interceptor
 * @param {*} config
 * @returns {*}
 */
function authRequestInterceptor(config) {
  const token = storage.getToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
}

/**
 * Axios Object to use
 * @type {*}
 */
export const axios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL_ADMIN,
});

axios.interceptors.request.use(authRequestInterceptor);
axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      if (storage.getToken() !== null) {
        localStorage.removeItem("BIZIA_token");
        localStorage.removeItem("user-info");
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);
export const Appaxios = Axios.create({
  baseURL: process.env.REACT_APP_API_URL_API,
});

Appaxios.interceptors.request.use(authRequestInterceptor);
Appaxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401) {
      if (storage.getToken() !== null) {
        localStorage.removeItem("BIZIA_token");
        localStorage.removeItem("user-info");
        window.location.reload();
      }
    }
    return Promise.reject(error);
  }
);
