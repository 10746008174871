export const LOGIN_API = `/users/login`;
export const FORGOT_API = `/users/forgot-password`;
export const RESET_API = `/users/reset-password`;
export const REFRESH_TOKEN = `/auth/refresh-tokens`;
export const VERIFICATION = `/users/verify-user`;
export const LOGOUT = `/auth/logout`;
export const VERIFICATION_RESET_TOKEN = `/users/validateforgotpasswordtoken`;
export const REGISTER = `/users/register`;
export const PAGES = `/pages/detail`;
export const USER_INFO = `/users/profile`;
export const CHANGE_PROFILE = `/users/change-profile`;
export const CHANGE_PASSWORD = `/users/change-password`;
export const STATE_ALL = `/pages/get-states`;
export const CITY_ALL = `/pages/get-cities`;
export const COUNTRYES = `/pages/get-countries`;
export const CATEGORY = `/pages/get-category`;
export const UPLOAD_MEDIA = `/franchise/upload-media`;
export const UPLOAD_MEDIA_SELLER = `/seller/upload-media`;
export const FRANCHISE_ADD = `/franchise/add`;
export const FRANCHISE_UPDATE = `/franchise/update`;
export const FRANCHISE_DELETE_MEDIA = `/franchise/delete-media`;
export const FRANCHISE_LIST = `/franchise/list`;
export const FRANCHISE_DETAILS = `/franchise/details`;
export const FRANCHISE_DELETE = `/franchise/delete`;
export const SELLER_ADD = `/seller/add`;
export const SELLER_LIST = `/seller/list`;
export const SELLER_DELETE = `/seller/delete`;
export const SELLER_DETAILS = `/seller/details`;
export const SELLER_UPDATE = `/seller/update`;
export const LEAD_LIST = `/lead/list`;
export const LEAD_UPDATE = `/lead/update`;
export const LEAD_DETAILS = `/lead/details`;
export const STRIPE_PAYMENT = `/payment/get-payment-intent`;
export const PAYMENT_LIST = `/payment/list`;
export const PAYMENT_DETAILS = `/payment/details`;
export const SUBSCRIPTION_LIST = `/payment/active-subscription`;
export const SAVE_PAYMENT = `/payment/save-payment-intent`;
export const UPDATE_AUTO_RENEW = `/payment/update`;
export const SUBSCRIPTION_GET = `/pages/get-subscription`;
export const USER_DASHBOARD = `/users/dashboard`;
export const USER_NOTIFICATIONS_LIST = `/payment/notification-list`;
