import { Card } from "react-bootstrap";
import storage from "../../helpers/storage";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getdashboard, getUserDashboard } from "../../services/userService";
import Loader from "../../components/common/Loader";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { CustomSelect } from "../../components/common/CustomSelect";
import { yearData, monthData } from "../../helpers/helpersMethods";
export const Dashboard = () => {
  storage.setPageTitle("Dashboard");
  const [data, setData] = useState([]);
  const [dataGrid, setDataGrid] = useState([]);
  const [loading, setLoading] = useState(true);
  const [yearOptions, setYearOptions] = useState([]);
  const [monthOptions, setMonthOptions] = useState([]);
  const [selectYear, setSelectYear] = useState([]);
  const [selectMonth, setSelectMonth] = useState([]);
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }
  useEffect(() => {
    setLoading(true); // Set loading to true when the effect starts
    getdashboard();
  }, []);
  useEffect(() => {
    setLoading(true); // Set loading to true when the effect starts
    getdashboard({
      month: selectMonth,
      year: selectYear,
    });
  }, [selectYear, selectMonth]);
  const getdashboard = (params) => {
    getUserDashboard({ ...params })
      .then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          const startIndex = 1;
          const dataWithSNo = res.stats.map((item, index) => ({
            ...item,
            sNo: startIndex + index,
          }));
          setDataGrid(dataWithSNo);
          setYearOptions(yearData());
          setMonthOptions(monthData());
        },
        (error) => {
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
          }
        }
      )
      .finally(() => {
        setLoading(false); // Set loading to false when the effect finishes
      });
  };
  const handleChangeYear = (event) => {
    console.log(event.target.value, "ccc");
    if (event.target.value) {
      setSelectYear(event.target.value);
    } else {
      setSelectYear("");
      setSelectMonth("");
    }
  };
  const handleChangeMonth = (event) => {
    if (event.target.value) {
      setSelectMonth(event.target.value);
    } else {
      setSelectMonth("");
    }
  };
  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "name",
      headerName: "Business / Franchise Name",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "source",
      headerName: "Type",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "statename",
      headerName: "State Name",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "cityname",
      headerName: "City Name",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "leadsCount",
      headerName: "Count",
      editable: false,
      flex: 0.05,
      minWidth: 150,
      sortable: false,
    },
  ];
  return (
    <div className="d-flex flex-column h-100 pageContainer px-sm-1 w-100 pb-sm-1">
      {/*<div className="pageHeader px-3 py-2 my-1">
        <Row className="align-items-center">
          <Col>
            <h1 className="fw-semibold h4 my-2">Dashboard</h1>
          </Col>
        </Row>
      </div>*/}
      <div className="flex-grow-1 overflow-auto pageContent pb-3">
        <Card className="h-100 p-3 w-100 pageContentInner bg-transparent border-0">
          <div className="text-center mb-2">
            <div className="row g-3 justify-content-start">
              <div className="col-xl-4 col-md-4 col-sm-6 h-100">
                <Card className="bggreen text-white">
                  <Card.Body>
                    <div className="bg-white-tr rounded-circle h-64 w-64 m-auto d-flex align-items-center justify-content-center">
                      <svg
                        id="Job_search"
                        data-name="Job search"
                        xmlns="http://www.w3.org/2000/svg"
                        width="27"
                        height="30"
                        viewBox="0 0 20 20"
                      >
                        <g id="Job_Search-2" data-name="Job_Search">
                          <g
                            id="Group_21747"
                            data-name="Group 21747"
                            transform="translate(0 2.344)"
                          >
                            <path
                              id="Path_28727"
                              data-name="Path 28727"
                              d="M11.961,68.789,8.789,71.961h3.172Z"
                              transform="translate(-8.446 -68.446)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28728"
                              data-name="Path 28728"
                              d="M12.3,69.375A6.434,6.434,0,0,1,6.571,60H4.688v4.688H0V77.656H12.891V69.345C12.7,69.363,12.5,69.375,12.3,69.375ZM2.344,67.031H7.031V68.2H2.344Zm8.2,8.2h-8.2V74.063h8.2Zm0-2.344h-8.2V71.719h8.2Zm0-2.344h-8.2V69.375h8.2Z"
                              transform="translate(0 -60)"
                              fill="#fff"
                            />
                          </g>
                          <g
                            id="Group_21748"
                            data-name="Group 21748"
                            transform="translate(7.031)"
                          >
                            <circle
                              id="Ellipse_18"
                              data-name="Ellipse 18"
                              cx="0.586"
                              cy="0.586"
                              r="0.586"
                              transform="translate(4.688 3.516)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28729"
                              data-name="Path 28729"
                              d="M273.516,181.758a1.758,1.758,0,0,0-3.516,0v1.435a4.947,4.947,0,0,0,3.516,0Z"
                              transform="translate(-266.484 -172.969)"
                              fill="#fff"
                            />
                            <path
                              id="Path_28730"
                              data-name="Path 28730"
                              d="M185.273,0a5.272,5.272,0,0,0-2.93,9.656V8.789a2.933,2.933,0,0,1,2.93-2.93A1.758,1.758,0,1,1,187.031,4.1a1.76,1.76,0,0,1-1.758,1.758,2.933,2.933,0,0,1,2.93,2.93v.867A5.272,5.272,0,0,0,185.273,0Z"
                              transform="translate(-180)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                        <path
                          id="Path_28731"
                          data-name="Path 28731"
                          d="M421.263,240.377a6.447,6.447,0,0,1-.826.831l2.748,2.865.829-.829Z"
                          transform="translate(-404.014 -230.987)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    <Card.Title className="fs-30 mt-3 mb-2 fw-normal">
                      {data ? data.total_leads : ""}
                    </Card.Title>
                    <Card.Text className="fs-16 fw-medium">
                      Total No. of Leads
                    </Card.Text>
                  </Card.Body>
                </Card>
              </div>
            </div>
          </div>

          <div className="dashboardTable bg-white w-100 mt-4">
            <div className="dashboardTableInner p-1">
              <div className="dashboardTableTop d-flex justify-content-end mb-2 pb-1 px-2">
                <CustomSelect
                  label="Year"
                  placeholder="Select Year"
                  options={yearOptions}
                  name="Year"
                  value={selectYear}
                  handleChange={handleChangeYear}
                />
                <CustomSelect
                  label="Month"
                  placeholder="Select Month"
                  options={monthOptions}
                  name="Month"
                  value={selectMonth}
                  handleChange={handleChangeMonth}
                />
              </div>
              {
                <div className="dataGridMain dataGridMainHeight flex-fill px-1 w-100 dataGridMedia">
                  <DataGrid
                    id="dashboard"
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={dataGrid}
                    columns={columns}
                    disableColumnMenu
                  />
                </div>
              }
            </div>
          </div>
        </Card>
      </div>
      <Loader isLoading={loading} />
    </div>
  );
};
