import React, { useState } from "react";
import { Card, Col, Row, Stack, Image } from "react-bootstrap";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "../../../components/common/StripePaymentForm";
import "../../../CustomStripeForm.css";
import PaymentImg from "../../../assets/images/paymentImg.svg";
import { FaCreditCard } from "react-icons/fa";
import storage from "../../../helpers/storage";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const AddStrip = () => {
  const navigate = useNavigate();
  const getPaymentData = storage.getData("payment-data");

  const appearance = {
    theme: "stripe",
    variables: {
      colorPrimary: "#0570de",
      colorBackground: "#ffffff",
      colorText: "#30313d",
      colorDanger: "#df1b41",
      fontFamily: "Ideal Sans, system-ui, sans-serif",
      spacingUnit: "2px",
      borderRadius: "4px",
      // See all possible variables below
    },
  };
  const options = {
    appearance,
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <FaCreditCard size="34" />
                  </div>
                  Payment
                </h5>
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column py-4 px-3 h-100 overflow-auto paymentFormMain">
              <Row className="justify-content-end">
                <Col sm={6} lg={6} className="mb-3 pb-1 searchTopPanel">
                  <div className="p-4 paymentImg d-flex justify-content-end align-items-center">
                    <Image
                      className="img-fluid"
                      src={PaymentImg}
                      alt="Payment-Image"
                    />
                  </div>
                </Col>
                <Col sm={6} lg={6} className="mb-3 pb-1 searchTopPanel">
                  <div className="paymentFormInner py-5">
                    <Elements options={options} stripe={stripePromise}>
                      <StripePaymentForm />
                    </Elements>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default AddStrip;
