import React, { useEffect, useState } from "react";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { Box, TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getUserListAPI,
  changeUserStatus,
  deleteUser,
} from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import {
  getLeadList,
  getPaymentList,
  updateLeadStatus,
} from "../../../services/franchiseService";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../../components/common/dataGrid/CustomPagination";
import "../../../components/common/dataGrid/datagrid.scss";
import { Link, useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";

import {
  CapitalText,
  formatNumberWithTwoDecimals,
  truncateText,
} from "../../../helpers/helpersMethods";
import commonValue from "../../../constants/commonValue";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { SelectCell } from "../../../components/common/SelectCell";
import FormDatesPicker from "../../../components/common/FormDatesPicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { MdClose } from "react-icons/md";
const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#17B0B2",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllPayment = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [data, setData] = useState([]);
  const [mutation, setMutation] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState([]);
  const cookies = new Cookies();
  const [deletestatus, setDeletestatus] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [show, setShow] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [selectRow, setSelectRow] = useState("");
  const [selectDate, setSelectDate] = useState(null);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }
  useEffect(() => {
    if (selectValue) {
      const requestData = {
        id: selectRow,
        status: selectValue,
      };

      setLoading(true); // Show loader during API call

      // Make the API call to update the status
      updateLeadStatus(requestData).then(
        (response) => {
          const res = response?.data;
          setTimeout(() => {
            setLoading(false);
            if (response?.statusCode === 200) {
              // Update the data in your data grid
              const updatedData = data.map((item) => {
                if (item.id === selectRow) {
                  return { ...item, status: selectValue };
                }
                return item;
              });
              setData(updatedData);
            }
          }, 1000);
        },
        (error) => {
          setLoading(false);
          // Handle error and show a notification
          toast.error(error.response.data.msg);
        }
      );
    }
  }, [selectValue, selectRow]);

  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 100,
      maxWidth: 250,
    },
    {
      field: "id",
      headerName: "Business / Franchise Name",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      maxWidth: 400,
      renderCell: (props) =>
        //props.row.seller.name
        truncateText(
          props.row.franchise_business_type == "franchise"
            ? props.row.franchise.name
            : props.row.seller.name
        ),
    },

    {
      field: "franchise_business_type",
      headerName: "Type",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      maxWidth: 300,
      renderCell: (props) =>
        truncateText(CapitalText(props.row.franchise_business_type)),
    },
    {
      field: "price",
      headerName: "Amount($)",
      editable: false,
      flex: 0.6,
      minWidth: 50,
      maxWidth: 300,
      renderCell: (props) => formatNumberWithTwoDecimals(props.row.price / 100),
    },

    {
      field: "payment_date",
      headerName: "Payment Date",
      editable: false,
      flex: 0.6,
      minWidth: 100,
      maxWidth: 300,
      renderCell: (props) =>
        moment(props.row.payment_date).format(
          commonValue.commaSeparatedShowDateTime
        ),
    },

    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/payment"
            isViewEnable={true}
            isEditEnable={false}
            isDeleteEnable={false}
            redirectID={props.row.id}
            rowData={props.row}
          />
        );
      },
    },
  ];

  // Searching and listing user list
  // const handleValueChange = (value, type) => {
  //   setSelectedValue(value);
  // };

  useEffect(() => {
    setCurrentPage(1);
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchtext: selectedValue,
      searchByDate: selectDate ? dayjs(selectDate).format("YYYY-MM-DD") : "",
    });
  }, [selectedValue, selectDate]);

  // Get user list details
  const fetchList = (params) => {
    toast.dismiss();
    setLoading(true);
    getPaymentList({ ...params }).then(
      (response) => {
        const res = response?.data;
        const itemsPerPage = params?.limit || 10;
        const currentPage = params?.page || 1;
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        const dataWithSNo = res.record.map((item, index) => ({
          ...item,
          sNo: startIndex + index,
        }));
        setLoading(false);
        setData(dataWithSNo);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
        setLoading(false);
      },
      (error) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }
      }
    );
  };

  // On page load fetch user list
  useEffect(() => {
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      searchtext: selectedValue,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchByDate: selectDate ? dayjs(selectDate).format("YYYY-MM-DD") : "",
    });
  }, [page, rowPerPage, sortColumn, sortDirection, currentPage]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
  };
  const handlePageSizeChange = (params) => {
    setRowPerPage(params.pageSize);
  };
  //change status
  const handleSelectRow = (id, value) => {
    setSelectRow(id);
    setSelectValue(value);
  };
  const handleClear = () => {
    setSelectDate(null);
  };
  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.327"
                      height="23.423"
                      viewBox="0 0 27.327 23.423"
                    >
                      <path
                        id="Icon_material-history"
                        data-name="Icon material-history"
                        d="M17.115,4.5A11.713,11.713,0,0,0,5.4,16.212H1.5l5.062,5.062.091.182,5.257-5.244h-3.9a9.153,9.153,0,1,1,2.681,6.428L8.839,24.488A11.709,11.709,0,1,0,17.115,4.5Zm-1.3,6.506v6.506l5.569,3.305.937-1.575-4.555-2.707v-5.53Z"
                        transform="translate(-1.5 -4.5)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Payment History
                </h5>
                {/* <Link
                  to={nameBasedProtectedRoutes.sellerAdd.path}
                  className="btn btn-primary"
                >
                  Add New
                </Link> */}

                {/* <Button variant="primary">Add Franchise</Button> */}
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
              <div className="theme-data-table-filter px-1">
                <ThemeProvider theme={customTheme}>
                  <Row className="justify-content-end">
                    <Col
                      sm={5}
                      lg={2}
                      className="mb-3 pb-1 dateTopPanel position-relative"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {selectDate && (
                          <div
                            className="close-icon-calendar"
                            onClick={handleClear}
                          >
                            <MdClose size={21} color="#7c7a7a" />
                          </div>
                        )}
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Select Date"
                            className="custom-datepicker custom-text-field"
                            value={selectDate}
                            onChange={(e) => {
                              console.log(e);
                              setSelectDate(e);
                            }}
                            maxDate={dayjs().subtract(0, "day")}
                            format="DD-MM-YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Col>
                    <Col
                      sm={5}
                      lg={2}
                      className="mb-3 pb-1 d-flex align-items-end"
                    >
                      <TextField
                        id="search-filter"
                        label="Search"
                        variant="standard"
                        className="custom-text-field"
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => {
                          setSelectedValue(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </ThemeProvider>
              </div>

              {
                <div className="dataGridMain flex-fill px-1 w-100">
                  <DataGrid
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={data}
                    columns={columns}
                    pageSize={rowPerPage}
                    rowCount={totalReults}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={(model) => {
                      // Extract the sorting information from the model
                      const sortField = model[0]?.field || "id";
                      const sortDirection = model[0]?.sort || "asc";
                      setSortColumn(sortField);
                      setSortDirection(sortDirection);
                      // Update the state and fetch new data based on the sorting
                      setSorting(model);
                      fetchList({
                        page,
                        limit: rowPerPage,
                        searchtext: selectedValue,
                        sortColumn: sortColumn,
                        sortDirection: sortDirection,
                      });
                    }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          page: currentPage - 1,
                          pageSize: rowPerPage,
                        },
                      },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={handlePageChange}
                    disableColumnMenu
                  />
                </div>
              }
            </div>
          </Card>
        </div>
      </div>
      <Loader isLoading={loading} />
    </>
  );
};
