import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import {userVerify } from "../../../services/authService"
import { nameBasedRoutes } from "../../../router/public"
import { FaCheckCircle, FaExclamationTriangle } from "react-icons/fa";
import { Image } from 'react-bootstrap'
import Logo from '../../../assets/images/logo.svg';
import Loader from "../../../components/common/Loader";

export const UserVerify = () => {
  const navigate = useNavigate();
  let token = "";
  const [loading, setLoading] = useState(false);
  const [tokenExpired, setTokenExpired] = useState();
  const search = useLocation().search;
  
  useEffect(() => {
    setLoading(true);
    token = new URLSearchParams(search).get("token");
    userVerify(token).then(
      (response) => {
        const res = response?.data;
        if (response?.statusCode === 200)
        {
          setLoading(false);
        }
        
      },
      (error) =>
      {
        setTimeout(() =>
        {
          setLoading(false);
        }, 1000);
       
        setTokenExpired(true);
      }
    );
    if (!token) {
      navigate(nameBasedRoutes.home.path);
    }
  }, []);
  

  return (
    <>
    {!loading  ?
    <>
    {!tokenExpired ? (
        <div className="min-vh-100 w-100 d-flex flex-column p-3">
        <div className="col-lg-7 m-auto text-center">
          <div className="mxw-600 m-auto">
            <div className="mb-5 text-center">
              <Image className="img-fluid text-blue" src={Logo} alt="Logo" width={184} height={62} />
            </div>
            <FaCheckCircle size={50} color="green"/>
            <h1 className="fs-3 fw-semibold my-3">Your account has been verified.</h1>
            <div className="d-inline-flex gap-2 mb-5">
              <Link to={nameBasedRoutes.home.path} className="btn btn-primary fw-semibold fs-13 text-white mw-90 mt-2">
                Back to Login
              </Link>
            </div>
          </div>
          
        </div>
      </div>
      ):<div className="min-vh-100 w-100 d-flex flex-column p-3">
      <div className="col-lg-7 m-auto text-center">
        <div className="mxw-600 m-auto">
          <div className="mb-5 text-center">
            <Image className="img-fluid text-blue" src={Logo} alt="Logo" width={184} height={62} />
          </div>
          <FaExclamationTriangle size={50} color="red"/>
          <h1 className="fs-3 fw-semibold my-3">Oops! Your Token has been expired</h1>
        </div>
        
      </div>
    </div>}</>
    :''}
    <Loader isLoading={loading} />
    </>
  )
}
