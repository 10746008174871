import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";
import Logo from "../../../assets/images/logo.svg";
import { getLeadDetails } from "../../../services/franchiseService";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import Loader from "../../../components/common/Loader";
import CustomView from "../../../components/common/CustomView";

export const View = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getLeadDetails(id).then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.franchise.path);
          }
        }
      );
    }
  }, [id]);

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      id="Job_search"
                      data-name="Job search"
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.748"
                      height="31.748"
                      viewBox="0 0 31.748 31.748"
                    >
                      <g id="Job_Search-2" data-name="Job_Search">
                        <g
                          id="Group_21747"
                          data-name="Group 21747"
                          transform="translate(0 3.72)"
                        >
                          <path
                            id="Path_28727"
                            data-name="Path 28727"
                            d="M13.825,68.789,8.789,73.825h5.036Z"
                            transform="translate(-8.244 -68.244)"
                            fill="#fff"
                          />
                          <path
                            id="Path_28728"
                            data-name="Path 28728"
                            d="M19.532,74.882A10.213,10.213,0,0,1,10.431,60H7.441v7.441H0V88.028H20.463V74.835C20.156,74.863,19.846,74.882,19.532,74.882ZM3.72,71.161h7.441v1.86H3.72ZM16.742,84.183H3.72v-1.86H16.742Zm0-3.72H3.72V78.6H16.742Zm0-3.72H3.72v-1.86H16.742Z"
                            transform="translate(0 -60)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Group_21748"
                          data-name="Group 21748"
                          transform="translate(11.161)"
                        >
                          <circle
                            id="Ellipse_18"
                            data-name="Ellipse 18"
                            cx="0.93"
                            cy="0.93"
                            r="0.93"
                            transform="translate(7.441 5.581)"
                            fill="#fff"
                          />
                          <path
                            id="Path_28729"
                            data-name="Path 28729"
                            d="M275.581,182.79a2.79,2.79,0,1,0-5.581,0v2.278a7.852,7.852,0,0,0,5.581,0Z"
                            transform="translate(-264.419 -168.839)"
                            fill="#fff"
                          />
                          <path
                            id="Path_28730"
                            data-name="Path 28730"
                            d="M188.371,0a8.369,8.369,0,0,0-4.651,15.329V13.952A4.656,4.656,0,0,1,188.371,9.3a2.79,2.79,0,1,1,2.79-2.79,2.793,2.793,0,0,1-2.79,2.79,4.656,4.656,0,0,1,4.651,4.651v1.377A8.369,8.369,0,0,0,188.371,0Z"
                            transform="translate(-180)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        id="Path_28731"
                        data-name="Path 28731"
                        d="M421.749,240.377a10.236,10.236,0,0,1-1.312,1.319l4.362,4.548,1.315-1.315Z"
                        transform="translate(-394.367 -225.472)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Lead Details
                </h5>
                <Link
                  to={nameBasedProtectedRoutes.lead.path}
                  className="btn btn-primary mw-84"
                >
                  Back
                </Link>
              </Stack>
            </div>

            {!loading && data ? (
              <div className="franchiseDtl">
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Generate Date"
                      value={data.created_at}
                      type="date"
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Current Status"
                      value={data.status}
                      type="text"
                      capital={true}
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Business / Franchise Name"
                      value={
                        data.franchise_business_type == "franchise"
                          ? data.franchise
                            ? data.franchise.name
                            : ""
                          : data.seller
                          ? data.seller.name
                          : ""
                      }
                      type="text"
                    />
                  </Col>
                </Row>
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Type"
                      value={data.franchise_business_type}
                      type="text"
                      capital={true}
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="User Name"
                      value={data.user ? data.user.name : "-"}
                      type="text"
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="User Email"
                      value={data.user ? data.user.email : "-"}
                      type="text"
                    />
                  </Col>
                </Row>
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Message"
                      value={data.message}
                      type="text"
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
            <Loader isLoading={loading} />
          </Card>
        </div>
      </div>
    </>
  );
};
