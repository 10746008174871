import moment from "moment";
import * as React from "react";
import { Form } from "react-bootstrap";
import commonValue from "../../constants/commonValue";
import { CapitalText } from "../../helpers/helpersMethods";

const CustomView = ({ label, value, type, capital }) => {
  return (
    <>
      <Form.Group className="pb-1 lh-sm">
        <Form.Label className="mb-1 fw-normal labelColor fs-13">
          {label}
        </Form.Label>
        {type == "date" ? (
          <div className="static-data fs-16">
            {value
              ? moment(value).format(commonValue.commaSeparatedShowDateTime)
              : "-"}
          </div>
        ) : (
          <div className="static-data fs-16">
            {value ? (capital ? CapitalText(value) : value) : "-"}
          </div>
        )}
      </Form.Group>
    </>
  );
};

export default CustomView;
