import React from "react";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import commonValue from "../constants/commonValue";
import { nameBasedProtectedRoutes } from "../router/protected";
import {
  getCategory,
  getCity,
  getCountry,
  getState,
  getSubscriptionDetails,
} from "../services/cmsService";
// import constantValues from "../constants/constantValues";
import storage from "./storage";

/**
 * Masking Email
 *
 * @param {string} [email='']
 * @returns {string}
 */
export const maskEmail = (email = "") => {
  return email.replace(
    /^(.)(.*)(.@.*)$/,
    (_, a, b, c) => a + b.replace(/./g, "*") + c
  );
};

// /**
//  * Calling List API as per modules
//  *
//  * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading
//  * @param {() => Promise<any>} method
//  * @param {React.Dispatch<React.SetStateAction<never[]>>} setData
//  * @param {*} setshowSnackBar
//  * @returns {any, setData: any, setshowSnackBar: any) => void}
//  */
// export const GetEntitiesList = (setLoading: React.Dispatch<React.SetStateAction<boolean>>, method: (ar1: any) => Promise<any>,
//   setData: React.Dispatch<React.SetStateAction<never[]>>, setshowSnackBar: any, params?: any) => {
//   setLoading(true);

//   method(serialize(params)).then((response: any) => {
//     setLoading(false);
//     if (response.results && response.results.length > 0) {
//       setData(response.results);
//     }

//   }).catch((error: any) => {
//     setLoading(false);
//     setshowSnackBar({
//       show: true,
//       message: error.response.data.message,
//       severity: 'error'
//     });
//   })
// }

/**
 * Add Entity by calling this method
 *
 */
export const AddEntitiyMethod = (
  setLoading,
  method,
  values,
  successMsg,
  redirectLink,
  navigate
) => {
  setLoading(true);
  method(values)
    .then((response) => {
      if (response?.statusCode === 200) {
        toast.success(response.msg);
      }
      setTimeout(() => {
        setLoading(false);
        navigate(redirectLink);
      }, 1000);
    })
    .catch((error) => {
      setLoading(false);
      const err = error?.response?.data;
      if (err?.statusCode === 400) {
        toast.error(err.msg);
      }
    });
};
export const AddPaymentEntitiyMethod = (
  setLoading,
  method,
  values,
  successMsg,
  redirectLink,
  navigate,
  setShow,
  type,
  subscription_id
) => {
  setLoading(true);
  method(values)
    .then((response) => {
      if (response?.statusCode === 200) {
        toast.success(response.msg);
      }
      setTimeout(() => {
        setLoading(false);
        console.log(response);
        setShow(true);
        const payment = {
          type: type,
          id: response.data.id,
          bussnessName: response.data.name,
          subscription_id: subscription_id,
          redirect: nameBasedProtectedRoutes.paymentList.path,
        };
        if (payment) {
          storage.clearPaymentData("payment-data");
          storage.setData("payment-data", payment);
        }
      }, 1000);
    })
    .catch((error) => {
      setLoading(false);
      const err = error?.response?.data;
      if (err?.statusCode === 400) {
        toast.error(err.msg);
      }
    });
};

/**
 * Edit entity
 * @date 17/07/2023 - 13:23:25
 *
 * @param {*} setLoading
 * @param {*} method
 * @param {*} id
 * @param {*} values
 * @param {*} successMsg
 * @param {*} redirectLink
 * @param {*} navigate
 */
export const EditEntitiyMethod = (
  setLoading,
  method,
  id,
  values,
  successMsg,
  redirectLink,
  navigate
) => {
  setLoading(true);
  toast.dismiss();
  method(values)
    .then((response) => {
      if (response?.statusCode === 200) {
        toast.success(response.msg);
      }
      setTimeout(() => {
        setLoading(false);
        if (redirectLink == "login") {
          storage.clearData();
          navigate("/");
          setLoading(false);
        } else {
          navigate(redirectLink);
        }
      }, 1000);
    })
    .catch((error) => {
      setLoading(false);
      const err = error?.response?.data;
      if (err?.statusCode === 400) {
        toast.error(err.msg);
      }
    });
};
export const EditEntitiyByTokenMethod = (
  setLoading,
  method,
  values,
  successMsg,
  redirectLink,
  navigate
) => {
  setLoading(true);
  toast.dismiss();
  method(values)
    .then((response) => {
      if (response?.statusCode === 200) {
        toast.success(response.msg);
      }
      setTimeout(() => {
        setLoading(false);
        if (redirectLink == "login") {
          storage.clearData();
          navigate("/");
          setLoading(false);
        } else {
          navigate(redirectLink);
        }
      }, 1000);
    })
    .catch((error) => {
      setLoading(false);
      const err = error?.response?.data;
      if (err?.statusCode === 400) {
        toast.error(err.msg);
      }
    });
};
export const getAllState = (id) => {
  return getState(id).then(
    (resp) => {
      console.log(resp?.data);
      return resp?.data;
    },
    (error) => {
      throw error;
    }
  );
};
export const getAllCity = (id) => {
  return getCity(id).then(
    (resp) => {
      return resp?.data;
    },
    (error) => {
      throw error;
    }
  );
};
export const getAllCountry = (id) => {
  return getCountry().then(
    (resp) => {
      return resp?.data;
    },
    (error) => {
      throw error;
    }
  );
};
export const getAllCategory = (type) => {
  return getCategory(type).then(
    (resp) => {
      return resp?.data;
    },
    (error) => {
      throw error;
    }
  );
};
export const getSubscription = () => {
  return getSubscriptionDetails().then(
    (resp) => {
      return resp?.data;
    },
    (error) => {
      throw error;
    }
  );
};

// /**
//  * Get Entity details by ID
//  *
//  * @param {React.Dispatch<React.SetStateAction<boolean>>} setLoading
//  * @param {(arg1: any) => Promise<any>} method
//  * @param {string} id
//  * @param {*} setData
//  */
// export const GetEntitiyByIDMethod = (setLoading: React.Dispatch<React.SetStateAction<boolean>>, method: (arg1: any) => Promise<any>, id: string,
//   setData: any) => {
//   setLoading(true);
//   method(id ? id : '').then(response => {
//     setLoading(false);
//     if (response.data) {
//       setData(response.data)
//     } else {
//       setData(response)
//     }
//   }).catch(error => {
//     setLoading(false);
//   })
// }

/**
 * Converting Object to Query Param
 *
 * @param {*} obj
 * @returns {*}
 */
// export const serialize = (obj: any) => {
//   let str: string[] = [];
//   for (let p in obj)
//     if (obj.hasOwnProperty(p)) {
//       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
//     }
//   return str.join("&");
// }
// export const logOut = async (navigate: NavigateFunction) => {
//   localStorage.removeItem('MYLO_token');
//   localStorage.removeItem('user-info');
//   navigate('/');
// };
export const truncateText = (text) => {
  if (text) {
    if (text.length > commonValue.MaxLength) {
      return `${text.slice(0, commonValue.MaxLength)}...`;
    }
  }
  return text;
};
export const formatNumberWithTwoDecimals = (number) => {
  const parsedNumber = parseFloat(number);
  return parsedNumber.toFixed(2);
};
export const yearData = () => {
  const currentYear = new Date().getFullYear();
  const startYear = 1900;
  const yearOptionsArr = [];

  for (let year = currentYear; year >= startYear; year--) {
    yearOptionsArr.push({ id: year.toString(), name: year.toString() });
  }

  return yearOptionsArr;
};
export const monthData = () => {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthOptionsArr = [];

  for (let i = 0; i < monthNames.length; i++) {
    monthOptionsArr.push({ id: (i + 1).toString(), name: monthNames[i] });
  }

  return monthOptionsArr;
};

export const CapitalText = (text) => {
  if (text) {
    const words = text.toLowerCase().split(" ");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    const status = capitalizedWords.join(" ");
    return status;
  }
};
// export const maskPhoneNumber = (phoneNumber:string) =>{
//   // Check if the phone number starts with '+1'
//   if (phoneNumber.startsWith('+1')) {
//     // Mask the last four digits of the phone number
//     return phoneNumber.replace(/^(\+1)[ -]?(\d{3})[ -]?(\d{3})[ -]?(\d{4})$/, '$1 $2-$3-$4');
//   } else {
//     // Return the original phone number if it doesn't start with '+1'
//     return phoneNumber;
//   }
// }

export const handleSelectAndPayClick = (subscriptionId,amount) => {
  const getPaymentData = storage.getData("payment-data");
  console.log('getPaymentData Seller List',getPaymentData);
  storage.clearPaymentData("payment-data");
  const newPaymentData = {
    type: getPaymentData.type,
    id: getPaymentData.id,
    bussnessName: getPaymentData.bussnessName,
    subscription_id: subscriptionId,
    redirect: getPaymentData.redirect,
    amount: amount,      
  };
  storage.setData("payment-data", newPaymentData);
  // getPaymentData.type
};
