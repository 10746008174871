import { useRoutes } from "react-router-dom";
import storage from "../helpers/storage";
import { NotFound } from "../pages/NotFound/NotFound";
// import { NotFound } from "../pages/NotFound/NotFound";
import { protectedRoutes } from "./protected";
import { publicRoutes } from "./public";

/**
 *
 * @returns Routes Elements
 */
export default function AppRoutes() {
  const commonRoutes = [
    {
      path: "*",
      element: <NotFound />,
    },
  ];
  const mainRoutes = storage.getToken() != null ? protectedRoutes : [];
  const element = useRoutes([...publicRoutes,...mainRoutes,...commonRoutes]);
  return <>{element}</>;
}

