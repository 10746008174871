var CryptoJS = require("crypto-js");

const storagePrefix = "BIZIA_";

/**
 * Storage management in app via localStorage
 *
 * @type {{ getToken: () => any; setToken: (token: string) => void; getRefreshToken: () => any; setRefreshToken: (token: string) => void; clearToken: () => void; setData: (key: string, data: any) => void; getData: (key: string) => any; }}
 */
const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`));
  },
  setToken: (token) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  getRefreshToken: () => {
    return JSON.parse(
      window.localStorage.getItem(`${storagePrefix}refreshToken`)
    );
  },
  setRefreshToken: (token) => {
    window.localStorage.setItem(
      `${storagePrefix}refreshToken`,
      JSON.stringify(token)
    );
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refreshToken`);
  },
  setData: (key, data) => {
    const cipherText = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      storagePrefix
    );
    window.localStorage.setItem(key, cipherText);
  },
  getData: (key) => {
    const dataString = window.localStorage.getItem(key)
      ? window.localStorage.getItem(key)
      : "";
    if (dataString) {
      const bytes = CryptoJS.AES.decrypt(dataString, storagePrefix);
      const decrypted = bytes.toString(CryptoJS.enc.Utf8);
      if(decrypted !=''){
        console.log('decrypted',decrypted);
        return JSON.parse(decrypted);
      }
    }
  },
  getStoragePrefix: () => {
    return storagePrefix;
  },
  clearData: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem("user-info");
  },
  getPageTitle: () => {
    return window.localStorage.getItem("title");
  },
  setPageTitle: (title) => {
    window.localStorage.setItem("title", title);
  },
  clearPaymentData: (key) => {
    window.localStorage.removeItem(key);
  },
};

export default storage;
