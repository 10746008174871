import { Button, Stack } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { ForgotPasswordSchema } from "../../../validations/Auth";
import { Formik } from "formik";
import { AuthHeader } from "../authHeader/AuthHeader";
import { Link, useNavigate } from "react-router-dom";

import { MdMail } from "react-icons/md";
import { Box } from "@mui/material";
import InputText from "../../../components/common/InputText";
import { useState } from "react";
import { forgotPassword } from "../../../services/authService";
import { nameBasedRoutes } from "../../../router/public";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";

export const ForgetPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  /**
   * setPassword
   * @date 11/07/2023 - 17:10:04
   *
   * @async
   * @param {*} email
   * @param {*} actions
   * @returns {*}
   */
  const handleSubmit = async (email, actions) => {
    setLoading(true);
    toast.dismiss();
    //debugger
    forgotPassword({ email })
      .then((response) => {
        setLoading(false);
        toast.success(response.msg);
        navigate(nameBasedRoutes.login.path);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.msg);
      });
  };
  return (
    <>
      <Formik
        validationSchema={ForgotPasswordSchema}
        initialValues={{
          email: "",
        }}
        onSubmit={(values, actions) => {
          actions.setSubmitting(false);
          handleSubmit(values.email, actions);
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <div className="d-flex p-3 min-vh-100 w-100 theme-body-bg align-items-center">
            <div className="mw-450 mx-auto my-4 py-md-5 text-center w-100">
              <div className="fw-normal text-center mb-5 pb-4">
                <AuthHeader />
              </div>
              <div className="bg-white d-inline-block mt-3 p-sm-4 p-2 rounded-3 text-start w-100 shadow-sm pt-40 position-relative">
                <div className="credTopInfo position-absolute">
                  <p className="fw-bold">Forgot Password</p>
                </div>
                <Form className="p-2" noValidate onSubmit={handleSubmit}>
                  <p className="fw-normal text-center mb-3 fs-14 mw-290 m-auto lh-sm subheading">
                    Enter your email and we'll send you a password reset link.
                  </p>

                  <Box className="credCoverB" sx={{ display: "flex" }}>
                    <MdMail
                      className={`inputIco ${
                        values.email.trim() !== "" ? "filled" : ""
                      }`}
                      size="15"
                      sx={{ color: "action.active", mr: 1, my: 0.5 }}
                    />

                    <InputText
                      controlId="email"
                      label="Email Address *"
                      type="text"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      errorsField={errors.email}
                      touched={touched.email}
                      className="w-100 fs-16 mb-4 custom-text-field"
                      InputLabelProps={{
                        className: "fs-15 grey-color",
                      }}
                      value={values.email}
                      name="email"
                    />
                  </Box>

                  <Stack gap={4} className="justify-content-center">
                    <Button
                      className="fw-semibold fs-18 text-white min-h-48 mt-2 w-100 credBtns"
                      variant="primary"
                      type="submit"
                    >
                      Send
                    </Button>
                    <Link
                      className="fs-14 text-decoration-none fw-medium d-flex justify-content-center color-purple"
                      to="/"
                    >
                      Back to Login
                    </Link>
                  </Stack>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Formik>
      <Loader isLoading={loading} />
    </>
  );
};
