import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import "@stripe/stripe-js";
import { getStripePayment } from "../../services/userService";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import storage from "../../helpers/storage";
import Loader from "./Loader";
import { savePayment } from "../../services/franchiseService";
import { nameBasedProtectedRoutes } from "../../router/protected";

const StripePaymentForm = () => {
  const location = useLocation();
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const getPaymentData = storage.getData("payment-data");
  console.log('getPaymentData Stripe',getPaymentData);
  if (!getPaymentData) {
    toast.dismiss();
    //toast.error("Please select seller or franchise for payment");
    navigate(nameBasedProtectedRoutes.franchise.path);
    //
  }
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    if (payload.error) {
      toast.error(payload.error.message);
      return;
    }
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);

    // Call your server to create a Setup Intent
    try {
      if (getPaymentData) {
        const paymentPostData = {
          subscription_id: getPaymentData.subscription_id,
          type: getPaymentData.type,
          franchise_business_type_id: getPaymentData.id,
          franchise_business_name: getPaymentData.bussnessName,
        };
        setLoading(true);
        const response = await getStripePayment(paymentPostData);
        const res = response?.data;
        if (res) {
          const clientSecret = res.client_secret;
          try {
            const cardPayment = await stripe
              .confirmCardPayment(clientSecret, {
                payment_method: {
                  card: elements.getElement(CardNumberElement),
                },
                setup_future_usage: "off_session",
              })
              .then((response) => {
                if (response.error) {
                  throw response;
                }
                handlePayment(response.paymentIntent);
                //toast.success("Payment successfully");
                //console.log(response);
              })
              .catch((error) => {
                toast.error(error.error.message);
              });

            setLoading(false);
          } catch (confirmError) {
            setLoading(false);
            toast.error("An error occurred while confirming payment.");
          }
        } else {
          setLoading(false);
          toast.error("No data received from API.");
          // Handle the case where no data is received from the API.
        }
      } else {
        setLoading(false);
        toast.error("No data received data please try again");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error fetching stripe payment:", error);
      // Handle the error from getStripePayment() here
    }
  };

  const handlePayment = (paymentIntent) => {
    if (paymentIntent) {
      const savePaymentIntent = {
        subscription_id: getPaymentData.subscription_id,
        payment_intent: paymentIntent.id,
        franchise_business_type: getPaymentData.type,
        franchise_business_type_id: getPaymentData.id,
      };
      savePayment(savePaymentIntent)
        .then((response) => {
          if (response?.statusCode === 200) {
            toast.success(response.msg);
          }
          setTimeout(() => {
            setLoading(false);
            navigate(getPaymentData.redirect);
            storage.clearPaymentData("payment-data");
          }, 1000);
        })
        .catch((error) => {
          setLoading(false);
          const err = error?.response?.data;
          if (err?.statusCode === 400) {
            toast.error(err.msg);
          }
        });
    }
  };

  const elementOptions = {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  return (
    <div>
      <div className="amount-paid fs-16">{`Amount to be Paid : $${getPaymentData.amount}`}</div>
      <form className="payment-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="cardNumber">Card Number</label>
          <CardNumberElement options={elementOptions} id="cardNumber" />
        </div>
        <div className="form-group">
          <label htmlFor="cardExpiry">Expiry Date</label>
          <CardExpiryElement options={elementOptions} id="cardExpiry" />
        </div>
        <div className="form-group">
          <label htmlFor="cardCvc">CVC</label>
          <CardCvcElement options={elementOptions} id="cardCvc" />
        </div>
        <button type="submit" disabled={!stripe} className="pay-button">
          Pay
        </button>
      </form>
      {paymentError && <p style={{ color: "red" }}>{paymentError}</p>}
      {paymentSuccess && <p style={{ color: "green" }}>Payment successful!</p>}
      <Loader isLoading={loading} />
    </div>
  );
};

export default StripePaymentForm;
