import * as yup from "yup";
import {
  CONFIRM_PASSWORD_REQUIRED,
  EMAIL_REQUIRED,
  MAXIMUM_32_CHAR,
  MAXIMUM_50_CHAR,
  MINIMUM_3_CHAR,
  MINIMUM_8_CHAR,
  NAME_REQUIRED,
  PASSWORD_MUST_MATCH,
  PASSWORD_REGEX,
  PASSWORD_REQUIRED,
  // USER_NAME_REQUIRED,
  VALID_EMAIL,
} from "./ValidationErrors";

/**
 * LoginSchema for Validation
 * @date 4/11/2023 - 4:57:16 PM
 *
 * @type {*}
 */

export const LoginSchema = yup.object().shape({
  // username: yup.string().required(USER_NAME_REQUIRED),
  email: yup.string().required(EMAIL_REQUIRED).email(VALID_EMAIL),
  password: yup.string().required(PASSWORD_REQUIRED),
});

/**
 * ResetPasswordSchema for validation
 * @type {*}
 */
export const ResetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required(PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(32, MAXIMUM_32_CHAR)
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
      PASSWORD_REGEX
    ),
  confirmPassword: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], PASSWORD_MUST_MATCH),
});

/**
 * ForgotPasswordSchema for validation
 * @date 4/12/2023 - 10:13:56 AM
 *
 * @type {*}
 */
export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required(EMAIL_REQUIRED)
    .email(VALID_EMAIL)
    .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, VALID_EMAIL),
});
export const registerSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(NAME_REQUIRED),
  email: yup.string().trim().required(EMAIL_REQUIRED).email(VALID_EMAIL),
  password: yup
    .string()
    .trim()
    .required(PASSWORD_REQUIRED)
    .min(8, MINIMUM_8_CHAR)
    .max(32, MAXIMUM_32_CHAR)
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?!.*\s).{8,}$/,
      PASSWORD_REGEX
    ),
  confirmPassword: yup
    .string()
    .required(CONFIRM_PASSWORD_REQUIRED)
    .oneOf([yup.ref("password"), ""], PASSWORD_MUST_MATCH),
});
