import {
  FRANCHISE_ADD,
  FRANCHISE_DELETE,
  FRANCHISE_DELETE_MEDIA,
  FRANCHISE_DETAILS,
  FRANCHISE_LIST,
  FRANCHISE_UPDATE,
  LEAD_DETAILS,
  LEAD_LIST,
  LEAD_UPDATE,
  PAYMENT_DETAILS,
  PAYMENT_LIST,
  SAVE_PAYMENT,
  SELLER_ADD,
  SELLER_DELETE,
  SELLER_DETAILS,
  SELLER_LIST,
  SELLER_UPDATE,
  SUBSCRIPTION_LIST,
  UPDATE_AUTO_RENEW,
} from "../constants/paths";
import { axios } from "./axios";

/**
 * get user info
 * @date 17/07/2023 - 12:48:51
 *
 * @returns {*}
 */
export const addFranchise = (data) => {
  return axios.post(FRANCHISE_ADD, data);
};

export const updateFranchise = (data) => {
  return axios.post(FRANCHISE_UPDATE, data);
};

export const getFranchiseList = (params) => {
  return axios.get(`${FRANCHISE_LIST}`, { params });
};

export const getFranchiseDetails = async (id) => {
  return axios.get(`${FRANCHISE_DETAILS}?id=${id}`);
};
export const franchiseMediaDelete = async (id, type) => {
  return axios.get(`${FRANCHISE_DELETE_MEDIA}?id=${id}&type=${type}`);
};
export const franchiseDelete = async (id) => {
  return axios.get(`${FRANCHISE_DELETE}?id=${id}`);
};

//seller
export const addSeller = (data) => {
  return axios.post(SELLER_ADD, data);
};
export const getSellerList = (params) => {
  return axios.get(`${SELLER_LIST}`, { params });
};
export const sellerDelete = async (id) => {
  return axios.get(`${SELLER_DELETE}?id=${id}`);
};
export const getSellerDetails = async (id) => {
  return axios.get(`${SELLER_DETAILS}?id=${id}`);
};

export const updateSeller = (data) => {
  return axios.post(SELLER_UPDATE, data);
};
///lead list

export const getLeadList = (params) => {
  return axios.get(`${LEAD_LIST}`, { params });
};
/**
 * Lead update
 * @date 03/08/2023 - 14:49:58
 *
 * @param {*} params
 * @returns {*}
 */
export const updateLeadStatus = (data) => {
  return axios.post(`${LEAD_UPDATE}`, data);
};

export const getLeadDetails = async (id) => {
  return axios.get(`${LEAD_DETAILS}?id=${id}`);
};
//Payment
export const getPaymentList = (params) => {
  return axios.get(`${PAYMENT_LIST}`, { params });
};
export const getPaymentDetails = async (id) => {
  return axios.get(`${PAYMENT_DETAILS}?id=${id}`);
};
//active-subscription
export const getActiveSubscriptionList = (params) => {
  return axios.get(`${SUBSCRIPTION_LIST}`, { params });
};

export const savePayment = (data) => {
  return axios.post(`${SAVE_PAYMENT}`, data);
};
export const updatePaymentRenew = (data) => {
  return axios.post(`${UPDATE_AUTO_RENEW}`, data);
};
