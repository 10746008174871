import { MdDashboard, MdBusinessCenter } from "react-icons/md";

export const NavItems = [
  {
    id: 1,
    menuName: "Dashboard",
    menuIcon: <MdDashboard size={22} />,
    path: "/app/dashboard",
  },
  // {
  //   id: 2,
  //   menuName: "User Management",
  //   menuName: "Users",
  //   menuIcon: <MdSupervisorAccount size={23} />,
  //   path: "/app/users"
  // },
  {
    id: 2,
    menuName: "Franchise",
    menuIcon: (
      <svg
        id="Franchise_3_"
        data-name="Franchise (3)"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <path
          id="Path_28704"
          data-name="Path 28704"
          d="M49.172,319.3h6.367v1.172h1.172V319.3h6.368v1.172H64.25v-1.758a.586.586,0,0,0-.586-.586H56.711V317H55.539v1.133H48.586a.586.586,0,0,0-.586.586v1.758h1.172Z"
          transform="translate(-46.125 -304.617)"
          fill="#fff"
        />
        <path
          id="Path_28705"
          data-name="Path 28705"
          d="M390.336,436h-3.75a.586.586,0,0,0-.586.586v1.8a.586.586,0,0,0,.586.586h3.75a.586.586,0,0,0,.586-.586v-1.8A.586.586,0,0,0,390.336,436Z"
          transform="translate(-370.922 -418.969)"
          fill="#fff"
        />
        <path
          id="Path_28706"
          data-name="Path 28706"
          d="M4.336,436H.586a.586.586,0,0,0-.586.586v1.8a.586.586,0,0,0,.586.586h3.75a.586.586,0,0,0,.586-.586v-1.8A.586.586,0,0,0,4.336,436Z"
          transform="translate(0 -418.969)"
          fill="#fff"
        />
        <path
          id="Path_28707"
          data-name="Path 28707"
          d="M197.327,436h-3.75a.586.586,0,0,0-.586.586v1.8a.586.586,0,0,0,.586.586h3.75a.586.586,0,0,0,.586-.586v-1.8A.586.586,0,0,0,197.327,436Z"
          transform="translate(-185.452 -418.969)"
          fill="#fff"
        />
        <path
          id="Path_28708"
          data-name="Path 28708"
          d="M117.569,1.172h.039V11.211h2.7V7.617a.586.586,0,0,1,.586-.586h3.048a.586.586,0,0,1,.586.586v3.594h2.7V1.172h.039a.586.586,0,0,0,0-1.172h-9.688a.586.586,0,0,0,0,1.172Zm2.969,1.172h3.751a.586.586,0,0,1,0,1.172h-3.751a.586.586,0,0,1,0-1.172Zm0,2.344h3.751a.586.586,0,0,1,0,1.172h-3.751a.586.586,0,0,1,0-1.172Z"
          transform="translate(-112.413)"
          fill="#fff"
        />
        <path
          id="Path_28709"
          data-name="Path 28709"
          d="M231.983,210h1.876v3.008h-1.876Z"
          transform="translate(-222.921 -201.797)"
          fill="#fff"
        />
      </svg>
    ),
    path: "/app/franchise",
  },
  {
    id: 3,
    // menuName: "Onboarding Questions",
    menuName: "Business Seller",
    menuIcon: <MdBusinessCenter size={21} />,
    path: "/app/seller",
  },

  {
    id: 4,
    // menuName: "Massage Templates",
    menuName: "Leads",
    menuIcon: (
      <svg
        id="Job_search"
        data-name="Job search"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
      >
        <g id="Job_Search-2" data-name="Job_Search">
          <g
            id="Group_21747"
            data-name="Group 21747"
            transform="translate(0 2.344)"
          >
            <path
              id="Path_28727"
              data-name="Path 28727"
              d="M11.961,68.789,8.789,71.961h3.172Z"
              transform="translate(-8.446 -68.446)"
              fill="#fff"
            />
            <path
              id="Path_28728"
              data-name="Path 28728"
              d="M12.3,69.375A6.434,6.434,0,0,1,6.571,60H4.688v4.688H0V77.656H12.891V69.345C12.7,69.363,12.5,69.375,12.3,69.375ZM2.344,67.031H7.031V68.2H2.344Zm8.2,8.2h-8.2V74.063h8.2Zm0-2.344h-8.2V71.719h8.2Zm0-2.344h-8.2V69.375h8.2Z"
              transform="translate(0 -60)"
              fill="#fff"
            />
          </g>
          <g
            id="Group_21748"
            data-name="Group 21748"
            transform="translate(7.031)"
          >
            <circle
              id="Ellipse_18"
              data-name="Ellipse 18"
              cx="0.586"
              cy="0.586"
              r="0.586"
              transform="translate(4.688 3.516)"
              fill="#fff"
            />
            <path
              id="Path_28729"
              data-name="Path 28729"
              d="M273.516,181.758a1.758,1.758,0,0,0-3.516,0v1.435a4.947,4.947,0,0,0,3.516,0Z"
              transform="translate(-266.484 -172.969)"
              fill="#fff"
            />
            <path
              id="Path_28730"
              data-name="Path 28730"
              d="M185.273,0a5.272,5.272,0,0,0-2.93,9.656V8.789a2.933,2.933,0,0,1,2.93-2.93A1.758,1.758,0,1,1,187.031,4.1a1.76,1.76,0,0,1-1.758,1.758,2.933,2.933,0,0,1,2.93,2.93v.867A5.272,5.272,0,0,0,185.273,0Z"
              transform="translate(-180)"
              fill="#fff"
            />
          </g>
        </g>
        <path
          id="Path_28731"
          data-name="Path 28731"
          d="M421.263,240.377a6.447,6.447,0,0,1-.826.831l2.748,2.865.829-.829Z"
          transform="translate(-404.014 -230.987)"
          fill="#fff"
        />
      </svg>
    ),
    path: "/app/lead",
  },
  {
    id: 5,
    menuName: "Payment History",
    // menuName: "Transactions",
    menuIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="19.497"
        height="16.712"
        viewBox="0 0 19.497 16.712"
      >
        <path
          id="Icon_material-history"
          data-name="Icon material-history"
          d="M12.641,4.5a8.357,8.357,0,0,0-8.356,8.356H1.5l3.612,3.612.065.13,3.751-3.742H6.142a6.53,6.53,0,1,1,1.913,4.586L6.736,18.761A8.354,8.354,0,1,0,12.641,4.5Zm-.928,4.642v4.642l3.974,2.358.668-1.123-3.25-1.931V9.142Z"
          transform="translate(-1.5 -4.5)"
          fill="#fff"
        />
      </svg>
    ),
    path: "/app/payment",
  },

  {
    id: 6,
    menuName: "My Account",
    menuIcon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16.631"
        height="16.631"
        viewBox="0 0 16.631 16.631"
      >
        <g
          id="Group_21814"
          data-name="Group 21814"
          transform="translate(-2 -2)"
        >
          <path
            id="Path_28742"
            data-name="Path 28742"
            d="M5.2,14.556a8.677,8.677,0,0,1,2.37-1.278,8.283,8.283,0,0,1,5.488,0,8.677,8.677,0,0,1,2.37,1.278,6.427,6.427,0,0,0,1.133-1.933,6.518,6.518,0,0,0,.405-2.308,6.413,6.413,0,0,0-1.944-4.709,6.413,6.413,0,0,0-4.709-1.944A6.413,6.413,0,0,0,5.607,5.607a6.413,6.413,0,0,0-1.944,4.709,6.518,6.518,0,0,0,.405,2.308A6.427,6.427,0,0,0,5.2,14.556Zm5.114-3.409a2.875,2.875,0,0,1-2.91-2.91,2.875,2.875,0,0,1,2.91-2.91,2.875,2.875,0,0,1,2.91,2.91,2.875,2.875,0,0,1-2.91,2.91Zm0,7.484a8.1,8.1,0,0,1-3.243-.655,8.3,8.3,0,0,1-4.418-4.418A8.1,8.1,0,0,1,2,10.315a8.1,8.1,0,0,1,.655-3.243A8.3,8.3,0,0,1,7.072,2.655,8.1,8.1,0,0,1,10.315,2a8.1,8.1,0,0,1,3.243.655,8.3,8.3,0,0,1,4.418,4.418,8.1,8.1,0,0,1,.655,3.243,8.1,8.1,0,0,1-.655,3.243,8.3,8.3,0,0,1-4.418,4.418A8.1,8.1,0,0,1,10.315,18.631Z"
            fill="#fff"
          />
        </g>
      </svg>
    ),
    path: "/app/myaccount",
  },

  // {
  //   id: 9,
  //   menuName: "Video",
  //   menuName: "CMS Pages",
  //   menuIcon: <svg xmlns="http://www.w3.org/2000/svg" width="15.985" height="15.985" viewBox="0 0 15.985 15.985">
  //               <g id="Architecture_and_city" data-name="Architecture and city" transform="translate(0)">
  //                 <path id="Path_28725" data-name="Path 28725" d="M0,160.554a1.719,1.719,0,0,0,1.717,1.717H14.268a1.719,1.719,0,0,0,1.717-1.717V151H0Zm9.4-1.592H6.587a.937.937,0,1,1,0-1.873H9.4a.937.937,0,1,1,0,1.873Zm-4.215-6.088H10.8a.468.468,0,1,1,0,.937H5.183a.468.468,0,1,1,0-.937Zm-1.873,1.873h9.366a.468.468,0,1,1,0,.937H3.309a.468.468,0,1,1,0-.937Zm0,0" transform="translate(0 -146.286)" fill="#fff"/>
  //                 <path id="Path_28726" data-name="Path 28726" d="M14.268,0H1.717A1.719,1.719,0,0,0,0,1.717V3.778H15.985V1.717A1.719,1.719,0,0,0,14.268,0Zm-4.4,2.841a.468.468,0,1,1,.468-.468A.468.468,0,0,1,9.865,2.841Zm1.873,0a.468.468,0,1,1,.468-.468A.468.468,0,0,1,11.739,2.841Zm1.873,0a.468.468,0,1,1,.468-.468A.468.468,0,0,1,13.612,2.841Zm0,0" fill="#fff"/>
  //               </g>
  //             </svg>,
  //   path: "/app/settings"
  // },

  // {
  //   id: 10,
  //   menuName: "CMS Pages",
  //   menuName: "Reports & Stats",
  //   menuIcon: <IoIosStats size={21} />,
  //   path: "/app/cms"
  // },
  // {
  //   id: 11,
  //   menuName: "Contact Us Inquiries",
  //   menuIcon: <FaConciergeBell size={17} />,
  //   path: "/app/faq"
  // }
];
