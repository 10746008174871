import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Dropdown, DropdownItem } from "reactstrap";
import { nameBasedProtectedRoutes } from "../../router/protected";
import {
  getNotificationList
} from "../../services/userService";

import Loader from "./Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import commonValue from "../../constants/commonValue";
// import { validation } from "constants/constant";

const LIMIT = 5;

function Notification(props) {
  const [items, setItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const userId = localStorage.getItem("admin-id");
  useEffect(() => {
    console.log("comes")
    fetchData();
    return () =>{
      console.log("okk")
    }
  }, []);

  /* useEffect(() => {
     
    refreshFunction();
  }, [props.notificationCount]);*/ 

  // Fetch the notification list
  const fetchData = async () => {
    setLoading(true)
    await getNotificationList({
      page: activePage,
      limit: LIMIT,
      id: userId,
    })
      .then((response) => {
        console.log('activePage',activePage);
        setActivePage(activePage + 1);
        setItems([...items, ...response?.data?.record]);
        setTotalItems(response?.data?.totalResults);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Refresh the list on pull down the notification
  const refreshFunction = async () => {
    setLoading(true)
    await getNotificationList({
      page: 1,
      limit: LIMIT,
      id: userId,
    })
      .then((response) => {
        console.log(response.data.record);
        setActivePage(2);
        setItems([...response?.data?.record]);
        setTotalItems(response?.data?.totalResults);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getDateDifferenceString = (dateNoti) => {
    let date = moment(dateNoti);
    // Calculate the difference in days
    let daysDifference = moment().diff(date, 'days');
    // Format the date accordingly
    let formattedDate = daysDifference < 1 ? date.fromNow() : date.format('MMMM D, YYYY');
    return formattedDate;
  }
  const getLinkForNotification = (notificationId, otherId,franchiseSellerId, otherTppe) => {
    let link = nameBasedProtectedRoutes.lead.path;
    navigate(link);
    props.setNotificationBell(false)
    // console.log('link',link);
    
  }
  return (
    <>
      <InfiniteScroll
        dataLength={items.length} //This is important field to render the next data
        next={fetchData}
        hasMore={items.length < totalItems}
        scrollableTarget="scrollableDiv"
        endMessage={
          items.length > 0 ? (
            <DropdownItem>
              <p style={{ textAlign: "center" }}>
              </p>
            </DropdownItem>
          ) : (
            ""
          )
        }
        // Commented code is for client feedback if it approved than uncomment it otherwise will be remove
        // pullDownToRefresh={true}
        // // below props only if you need pull down functionality
        // refreshFunction={refreshFunction}
        // // pullDownToRefresh
        // pullDownToRefreshThreshold={50}
        // pullDownToRefreshContent={
        //   <p style={{ textAlign: "center" }}>&#8595; Pull down to refresh</p>
        // }
        // releaseToRefreshContent={
        //   <p style={{ textAlign: "center" }}>&#8593; Release to refresh</p>
        // }
      >
        {items && items.length > 0 ? (
          items.map((item) => (
            <div key={item.id} className="notifRow">
              <div>
              <div className="notification-box-item" onClick={()=>getLinkForNotification(item.notification_id,item.other_data,item.franchise_business_type_id,item.franchise_business_type)}>
                
                <div className="fs-14 textMsg" dangerouslySetInnerHTML={{__html: item.notification.message}}></div>  
                <div className="fs-13 fw-semibold gray-color">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.804" height="15.338" viewBox="0 0 13.804 15.338">
                  <g id="Group_21818" data-name="Group 21818" transform="translate(-3 -2)">
                    <path id="Path_28746" data-name="Path 28746" d="M4.534,17.338A1.538,1.538,0,0,1,3,15.8V5.068A1.538,1.538,0,0,1,4.534,3.534H5.3V2H6.834V3.534H12.97V2H14.5V3.534h.767A1.538,1.538,0,0,1,16.8,5.068V15.8a1.538,1.538,0,0,1-1.534,1.534Zm0-1.534H15.27V8.135H4.534Z" fill="#989ea5"/>
                  </g>
                </svg>
                  <span className="ms-2 notifLbl">{getDateDifferenceString(item.created_at)}{" "}</span>
                </div>
              </div>
              </div>
              
            </div>
          ))
        ) : totalItems !=0 ? (
            <Loader isLoading={loading} />
        ) : (
          totalItems != 0 ? (
          <div className="notification-box-item p-3">No Notification Found</div>)  : <div className="notification-box-item p-3">Loading...</div> 
          // <DropdownItem>{ validation.noNotification}</DropdownItem>
        )}
      </InfiniteScroll>
    </>
  );
}

export default Notification;
