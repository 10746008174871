import { MainLayout } from "../components/layouts/MainLayout";
import { Dashboard } from "../pages/Dashboard/Dashboard";
import { Profile } from "../pages/Profile/AddProfile";
import { ChangePassword } from "../pages/ChangePassword/ChangePassword";
import { Franchise } from "../pages/Franchise/routes";
import { Seller } from "../pages/Seller/routes";
import { Lead } from "../pages/Lead/routes";
import { Stripepayment } from "../pages/Strip/routes";
import { Payment } from "../pages/Payment/router";
import { MyAccount } from "../pages/MyAccount/router";

/**
 * Routes which can only be accessed after authentication
 * @type {{}}
 */
export const protectedRoutes = [
  {
    path: "/app",
    element: <MainLayout />,
    children: [
      { path: "", element: <Dashboard /> },
      { path: "dashboard", element: <Dashboard /> },
      { path: "franchise/*", element: <Franchise /> },
      { path: "seller/*", element: <Seller /> },
      { path: "lead/*", element: <Lead /> },
      { path: "stripe/*", element: <Stripepayment /> },
      { path: "payment/*", element: <Payment /> },
      { path: "myaccount/*", element: <MyAccount /> },
      { path: "profile", element: <Profile /> },
      { path: "change-password", element: <ChangePassword /> },
    ],
  },
];
export const nameBasedProtectedRoutes = {
  userDashboard: {
    name: "Dashboard",
    path: `/`,
  },

  dashborad: {
    name: "Dashboard",
    path: `/app/dashboard`,
  },
  franchise: {
    name: "franchise",
    path: `/app/franchise`,
  },

  franchiseAdd: {
    name: "franchise",
    path: `/app/franchise/add`,
  },
  seller: {
    name: "seller",
    path: `/app/seller`,
  },

  sellerAdd: {
    name: "seller",
    path: `/app/seller/add`,
  },
  lead: {
    name: "lead",
    path: `/app/lead`,
  },
  payment: {
    name: "payment",
    path: `/app/stripe`,
  },
  paymentList: {
    name: "payment",
    path: `/app/payment`,
  },
  profile: {
    name: "profile",
    path: `/app/profile`,
  },
  changePassword: {
    name: "change password",
    path: `/app/change-password`,
  },
  myaccount: {
    name: "myaccount",
    path: `/app/myaccount`,
  },
};
