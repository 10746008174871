import { Button, Modal, Stack } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { nameBasedProtectedRoutes } from "../../router/protected";

const PaymentModal = ({
  show,
  handleClose,
  setDeletestatus,
  recordDelete,
  details,
  navigate,
  handleSelectAndPayClick
}) => {
  console.log('details',details)
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      className="theme-modal paymentModal subscriptionModal"
    >
      <Modal.Header closeButton onClick={handleClose}>
        <Modal.Title className="fs-20 fw-bold text-black">
          Subscription
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-2">
        <div className="row">

        
        {
        details && details.map((item, index) => (
         <div className="col-md-12"> 
        <div className="fs-14 contentinner">
          <h3 className="fs-16 fw-bold">{item ? item.name : ""}</h3>
          <ul>
            <li>
              <FaCheck size="8" color="#6A3F91" />
              <span>{item ? item.description : ""}</span>
            </li>
          </ul>
          <h1 className="payAmount fw-bold">
            {item ? "$" + item.price : ""}
          </h1>
          <button
            className="btn btn-primary fw-medium payBtn"
            onClick={() => {
              handleSelectAndPayClick(item.id,item.price)
              navigate(nameBasedProtectedRoutes.payment.path);
            }}
          >
            Select & Pay
          </button>
        </div>
        </div>
        ))
        }
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;
