import * as yup from "yup";
import {
  MAXIMUM_500_CHAR,
  MAXIMUM_50_CHAR,
  MINIMUM_3_CHAR,
  NUMBER_ONLY,
  PHONE_NUMBER_NOT_VALID,
  PHONE_NUM_LENGTH,
  PRICE_VALID,
  REQUIRED,
  // USER_NAME_REQUIRED,
  VALID_EMAIL,
  VALID_NUMBER_ONLY,
  YEAR_NOT_VALID,
  YEAR_ONLY,
} from "./ValidationErrors";

export const addFranchiseSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(REQUIRED),
  founder_name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(REQUIRED),
  franchise_since: yup.string().trim(),
  description: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .required(REQUIRED),
  address: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .required(REQUIRED),
  total_units: yup
    .string()
    .trim()
    .matches(/^\d{1,10}$/, NUMBER_ONLY),
  verterned_own_unit: yup
    .string()
    .trim()
    .matches(/^\d{1,10}$/, NUMBER_ONLY),
  employees: yup
    .string()
    .trim()
    .matches(/^\d{1,10}$/, NUMBER_ONLY)
    .required(REQUIRED),
  year_founded: yup
    .string()
    .trim()
    .matches(/^\d{4}$/, YEAR_ONLY)
    .required(REQUIRED),
  annual_revenue: yup
    .string()
    .trim()
    .matches(/^\d+(\.\d+)?$/, PRICE_VALID)
    .required(REQUIRED),
  net_worth: yup
    .string()
    .trim()
    .matches(/^\d+(\.\d+)?$/, PRICE_VALID)
    .required(REQUIRED),
  country_id: yup.string().trim().required(REQUIRED),
  city_id: yup.string().trim().required(REQUIRED),
  state_id: yup.string().trim().required(REQUIRED),
  indus_category_id: yup.string().trim().required(REQUIRED),
  logo: yup.string().trim().required(REQUIRED),
});

export const addSellerSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(REQUIRED),
  owner_name: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(50, MAXIMUM_50_CHAR)
    .required(REQUIRED),
  address: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .required(REQUIRED),
  logo: yup.string().trim().required(REQUIRED),
  date_opened: yup.string().trim().required(REQUIRED),
  agreement_upload: yup.string().trim(),
  lease_upload: yup.string().trim(),
  year_left_lease: yup
    .string()
    .matches(/^\d+(\.\d+)?$/, VALID_NUMBER_ONLY)
    .trim(),
  annual_revenue: yup
    .string()
    .trim()
    .matches(/^\d+(\.\d+)?$/, PRICE_VALID)
    .required(REQUIRED),
  // year_in_business: yup.string().trim().required(REQUIRED),
  cost_of_business: yup.string().trim().required(REQUIRED),
  p_n_l_upload: yup.string().trim(),
  asking_price: yup
    .string()
    .trim()
    .matches(/^\d+(\.\d+)?$/, PRICE_VALID)
    .required(REQUIRED),
  biggest_expense: yup.string().trim().required(REQUIRED),
  is_price_negotiable: yup.string().trim().required(REQUIRED),
  contact_prefrence: yup.string().trim().required(REQUIRED),
  contact_phone: yup
    .string()
    .trim()
    .matches(/^\d{10}$/, PHONE_NUMBER_NOT_VALID)
    .min(10, PHONE_NUM_LENGTH)
    .max(10, PHONE_NUM_LENGTH),
  contact_email: yup.string().trim().email(VALID_EMAIL),
  country_id: yup.string().trim().required(REQUIRED),
  city_id: yup.string().trim().required(REQUIRED),
  state_id: yup.string().trim().required(REQUIRED),
  indus_category_id: yup.string().trim().required(REQUIRED),
  description: yup
    .string()
    .trim()
    .min(3, MINIMUM_3_CHAR)
    .max(500, MAXIMUM_500_CHAR)
    .required(REQUIRED),
});
