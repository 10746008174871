import { Route, Routes } from "react-router-dom";
import storage from "../../helpers/storage";
import { AllLead } from "./List/List";
import { View } from "./View/view";

export const Lead = () => {
  storage.setPageTitle("Leads");
  return (
    <Routes>
      <Route path="/" element={<AllLead />} />
      <Route path="/view/:id" element={<View />} />
    </Routes>
  );
};
