import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import InputText from "../../components/common/InputText";
import { FaPlus, FaTimes } from "react-icons/fa";
import { uploadMedia, uploadMediaSeller } from "../../services/userService";
import { franchiseMediaDelete } from "../../services/franchiseService";
import { nameBasedProtectedRoutes } from "../../router/protected";

const useDynamicFields = (
  fieldsConfig,
  limit,
  initialValues,
  isFormSubmitted,
  setLoading,
  type,
  uploadFolderName
) => {
  const [dynamicFields, setDynamicFields] = useState([{}]);

  useEffect(() => {
    // Update the dynamicFields state whenever initialValues change
    if (initialValues && type == "edit") {
      if (initialValues.length == 0) {
        setDynamicFields([{}]);
      } else {
        setDynamicFields(initialValues);
      }
    }
  }, [initialValues]);

  const fileInputRefs = useRef([]);
  const classText = "w-100 fs-16 custom-text-field";
  //console.log("xccc",initialValues);

  const handleAddField = () => {
    if (dynamicFields.length < limit) {
      console.log(dynamicFields);
      setDynamicFields((prevFields) => [...prevFields, {}]);
    }
  };

  const handleRemoveField = (index) => {
    const updatedFields = dynamicFields.filter((_, i) => i !== index);
    const fieldToRemove = dynamicFields[index];
    if (fieldToRemove["document_name"]) {
      // Perform the deletion operation for this specific field
      // Assuming you have access to the id and type required for the deletion
      let type = "document";
      let id = fieldToRemove["id"];
      franchiseMediaDelete(id, type).then(
        (resp) => {
          setLoading(false);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            //   navigate(nameBasedProtectedRoutes.franchise.path);
          }
        }
      );
      setDynamicFields(updatedFields);
    } else {
      // If the "document_name" field does not exist in the error, simply update the dynamicFields state
      setDynamicFields(updatedFields);
    }
  };

  const handleChange = (index, fieldName, value) => {
    const updatedFields = [...dynamicFields];
    updatedFields[index][fieldName] = value;
    setDynamicFields(updatedFields);
  };

  const handleFileChange = async (index, event, fieldName) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    // Access the field config for the current field being handled
    const fieldConfig = fieldsConfig.find((field) => field.name === fieldName);

    // Perform file type validation
    if (fieldConfig.acceptedFileTypes) {
      const acceptedTypes = fieldConfig.acceptedFileTypes.split(",");
      const fileType = file.name.substring(file.name.lastIndexOf("."));
      if (!acceptedTypes.includes(fileType)) {
        toast.error(
          `Invalid file type. Accepted types are: ${fieldConfig.acceptedFileTypes}`
        );
        fileInput.value = "";
        return;
      }
    }

    // Perform file size validation
    if (fieldConfig.maxFileSize) {
      const fileSizeInBytes = file.size;
      if (fileSizeInBytes > fieldConfig.maxFileSize) {
        toast.error(
          `File size exceeds the maximum limit of ${
            fieldConfig.maxFileSize / 1024 / 1024
          }MB`
        );
        fileInput.value = "";
        return;
      }
    }
    const updatedFields = [...dynamicFields];
    const formData = new FormData();
    formData.append("file", file);
    setLoading(true);
    if (uploadFolderName == "seller") {
      uploadMediaSeller(formData)
        .then((response) => {
          const res = response?.data;
          setLoading(false);
          // Handle the response data as needed
          updatedFields[index][fieldName] = res.file_name;
          setDynamicFields(updatedFields);
          console.log(dynamicFields);
          console.log("File uploaded successfully:", res);
        })
        .catch((error) => {
          setLoading(false);
          // Handle the error if the file upload fails
          console.error("File upload error:", error);
        });
    } else {
      uploadMedia(formData)
        .then((response) => {
          const res = response?.data;
          setLoading(false);
          // Handle the response data as needed
          updatedFields[index][fieldName] = res.file_name;
          setDynamicFields(updatedFields);
          console.log(dynamicFields);
          console.log("File uploaded successfully:", res);
        })
        .catch((error) => {
          setLoading(false);
          // Handle the error if the file upload fails
          console.error("File upload error:", error);
        });
    }
    // Assuming you want to update the dynamicFields state with the file name
    fileInput.value = "";
    //setDynamicFields(updatedFields);
    console.log("test");
  };

  // const validateField = (index, fieldName) => {
  //   if (!isFormSubmitted) {
  //     return "";
  //   }
  //   if (fieldName === "award_name") {
  //     const awardName = dynamicFields[index][fieldName];
  //     if (!awardName) {
  //       // Check if award_company_name is entered and award_name is empty
  //       if (
  //         dynamicFields[index]["award_company_name"] &&
  //         !dynamicFields[index]["award_name"]
  //       ) {
  //         return "Award Name is required.";
  //       } else {
  //         return "Award Name is required and should be between 3 and 50 characters.";
  //       }
  //     } else if (awardName.length < 3 || awardName.length > 50) {
  //       return "Award Name should be between 3 and 50 characters.";
  //     }
  //   } else if (fieldName === "award_company_name") {
  //     const awardCompanyName = dynamicFields[index][fieldName];
  //     if (!awardCompanyName) {
  //       // Check if award_name is entered and award_company_name is empty
  //       if (
  //         dynamicFields[index]["award_name"] &&
  //         !dynamicFields[index]["award_company_name"]
  //       ) {
  //         return "Recognition Company is required.";
  //       } else {
  //         //return "Recognition Company is required and should be between 3 and 50 characters.";
  //       }
  //     }
  //     else if (awardCompanyName.length < 3 || awardCompanyName.length > 50) {
  //       //return "Recognition Company should be between 3 and 50 characters.";
  //     }
  //   }
  //   if(fieldName === "relevant_link" && !dynamicFields[index][fieldName])
  //   {
  //     const relevantLink = dynamicFields[index][fieldName];
  //     if (!relevantLink) {
  //       return "Link is required.";
  //     }else {
  //       // Regular expression to check if the relevantLink is a valid URL
  //       const urlPattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?$/;
  //       if (!urlPattern.test(relevantLink))
  //       {

  //         return "Please enter a valid URL for the Link.";
  //       }
  //     }
  //   }

  //   if (fieldName === "document_file_name" && !dynamicFields[index][fieldName]) {
  //     // Check if award_company_name is entered and document is empty
  //     if (
  //       dynamicFields[index]["document_name"] &&
  //       !dynamicFields[index]["document_file_name"]
  //     ) {
  //       return "Document is required.";
  //     }
  //   } else if (
  //     fieldName === "document_name" &&
  //     !dynamicFields[index][fieldName]
  //   ) {
  //     // Check if document is entered and document_name is empty
  //     if (
  //       dynamicFields[index]["document_file_name"] &&
  //       !dynamicFields[index]["document_name"]
  //     ) {
  //       return "Document name is required.";
  //     }
  //   }

  //   // Add other field validations as needed
  //   // ...
  //   return ""; // Empty string means validation passed
  // };
  const validateField = (index, fieldName) => {
    if (!isFormSubmitted) {
      return "";
    }
    if (fieldName === "award_name" && !dynamicFields[index][fieldName]) {
      // Check if award_company_name is entered and award_name is empty
      if (
        dynamicFields[index]["award_company_name"] &&
        !dynamicFields[index]["award_name"]
      ) {
        return "Award Name is required.";
      }
    } else if (
      fieldName === "award_company_name" &&
      !dynamicFields[index][fieldName]
    ) {
      // Check if award_name is entered and award_company_name is empty
      if (
        dynamicFields[index]["award_name"] &&
        !dynamicFields[index]["award_company_name"]
      ) {
        return "Recognition Company is required.";
      }
    }
    if (
      fieldName === "document_file_name" &&
      !dynamicFields[index][fieldName]
    ) {
      // Check if award_company_name is entered and document is empty
      if (
        dynamicFields[index]["document_name"] &&
        !dynamicFields[index]["document_file_name"]
      ) {
        return "Document is required.";
      }
    } else if (
      fieldName === "document_name" &&
      !dynamicFields[index][fieldName]
    ) {
      // Check if document is entered and document_name is empty
      if (
        dynamicFields[index]["document_file_name"] &&
        !dynamicFields[index]["document_name"]
      ) {
        return "Document name is required.";
      }
    }

    // Add other field validations as needed
    // ...
    return ""; // Empty string means validation passed
  };

  const renderDynamicFields = () => {
    return dynamicFields.map((field, index) => (
      <Row key={index} className="gx-xl-5 position-relative widthAdjustment">
        {fieldsConfig.map((fieldConfig) => {
          const { type, label, name, validation } = fieldConfig;
          return (
            <Col key={name} sm={6} md={6} lg={5} className="pb-1">
              {type === "file" ? (
                <>
                  <div className="customBrowseCv position-relative">
                    <input
                      ref={(el) => (fileInputRefs.current[index] = el)}
                      type="file"
                      multiple
                      style={{ display: "none" }}
                      onChange={(event) => handleFileChange(index, event, name)}
                    />

                    <TextField
                      type="text"
                      value={dynamicFields[index][name] || ""}
                      className={classText}
                      variant="standard"
                      readOnly
                      error={!!validateField(index, name)}
                      helperText={validateField(index, name)}
                      disabled="true"
                    />
                    <Button
                      type="button"
                      onClick={() => fileInputRefs.current[index]?.click()}
                      variant="contained"
                      color="primary"
                      className="position-absolute bottom-0 end-0 fs-12 text-black d-flex align-items-center fw-medium mw-84 justify-content-center"
                    >
                      Browse
                    </Button>
                  </div>
                </>
              ) : (
                // Use Material-UI TextField for other types
                <TextField
                  type={type}
                  label={label}
                  value={field[name] || ""}
                  onChange={(e) => handleChange(index, name, e.target.value)}
                  error={!!validateField(index, name)}
                  helperText={validateField(index, name)}
                  className={classText}
                  variant="standard"
                />
              )}
              <Form.Control.Feedback type="invalid">
                {validateField(index, name)}
              </Form.Control.Feedback>
            </Col>
          );
        })}
        {index === 0 ? (
          <Col sm={1} md={1} lg={1} className="pb-1 ps-0 addRemoveBtn">
            <Button onClick={handleAddField} variant="primary">
              <FaPlus size={15} />
            </Button>
          </Col>
        ) : (
          <Col sm={1} md={1} lg={1} className="pb-1 ps-0 addRemoveBtn">
            <Button
              className="removeBtn"
              onClick={() => handleRemoveField(index)}
              variant="primary"
            >
              <FaTimes size={15} />
            </Button>
          </Col>
        )}
      </Row>
    ));
  };
  return { renderDynamicFields, dynamicFields };
};

export default useDynamicFields;
