import React, { useState } from "react";
import { Button, Card, Col, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { ChangePasswordSchema } from "../../validations/Profile";
import InputText from "../../components/common/InputText";
import Loader from "../../components/common/Loader";
import { changePassword } from "../../services/userService";

import { EditEntitiyByTokenMethod } from "../../helpers/helpersMethods";
import { CHANGE_PASSWORD_SUCCESS } from "../../validations/ValidationErrors";
import storage from "../../helpers/storage";
import { nameBasedProtectedRoutes } from "../../router/protected";

export const ChangePassword = () => {
  // Custom Select handling
  storage.setPageTitle("My Account");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const submitForm = (value) => {
    EditEntitiyByTokenMethod(
      setLoading,
      changePassword,
      value,
      CHANGE_PASSWORD_SUCCESS,
      `login`,
      navigate
    );
  };

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium fs-18">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <span className="theme-icon-box-inner">
                      {/* <FaUserAlt size={30} /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="31.748"
                        height="31.748"
                        viewBox="0 0 31.748 31.748"
                      >
                        <g
                          id="Group_21823"
                          data-name="Group 21823"
                          transform="translate(-2 -2)"
                        >
                          <path
                            id="Path_28742"
                            data-name="Path 28742"
                            d="M8.111,25.97a16.564,16.564,0,0,1,4.524-2.441,15.813,15.813,0,0,1,10.477,0,16.564,16.564,0,0,1,4.524,2.441A12.27,12.27,0,0,0,29.8,22.279a12.443,12.443,0,0,0,.774-4.405,12.242,12.242,0,0,0-3.711-8.989,12.242,12.242,0,0,0-8.989-3.711A12.242,12.242,0,0,0,8.885,8.885a12.242,12.242,0,0,0-3.711,8.989,12.443,12.443,0,0,0,.774,4.405A12.268,12.268,0,0,0,8.111,25.97Zm9.763-6.508a5.489,5.489,0,0,1-5.556-5.556A5.489,5.489,0,0,1,17.874,8.35a5.489,5.489,0,0,1,5.556,5.556,5.489,5.489,0,0,1-5.556,5.556Zm0,14.287a15.458,15.458,0,0,1-6.191-1.25A15.849,15.849,0,0,1,3.25,24.065,15.458,15.458,0,0,1,2,17.874a15.458,15.458,0,0,1,1.25-6.191A15.849,15.849,0,0,1,11.683,3.25,15.458,15.458,0,0,1,17.874,2a15.458,15.458,0,0,1,6.191,1.25A15.849,15.849,0,0,1,32.5,11.683a15.458,15.458,0,0,1,1.25,6.191,15.458,15.458,0,0,1-1.25,6.191A15.849,15.849,0,0,1,24.065,32.5,15.458,15.458,0,0,1,17.874,33.748Z"
                            fill="#fff"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                  Change Password
                </h5>
              </Stack>
            </div>
            <Formik
              validationSchema={ChangePasswordSchema}
              initialValues={{
                current_password: "",
                new_password: "",
                confirm_password: "",
              }}
              onSubmit={(values, actions) => {
                values.new_password = values.new_password.trim();
                values.current_password = values.current_password.trim();
                values.confirm_password = values.confirm_password.trim();
                const { confirm_password, ...formData } = values;
                submitForm(formData);
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
              }) => (
                <div className="flex-grow-1 d-flex flex-column px-1">
                  <div className="p-3 d-flex flex-column h-100">
                    <div className="d-flex flex-column flex-grow-1 theme-from">
                      <Row className="gx-xl-5">
                        <Col sm={6} md={6} lg={3} className="mt-3 pb-1">
                          <InputText
                            controlId="current_password"
                            label="Current Password *"
                            type="password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorsField={errors.current_password}
                            touched={touched.current_password}
                            className="w-100 fs-16 mb-3 custom-text-field"
                            InputLabelProps={{
                              className: "fs-15 grey-color",
                            }}
                            value={values.current_password}
                            name="current_password"
                          />
                        </Col>
                      </Row>
                      <Row className="gx-xl-5">
                        <Col sm={6} md={6} lg={3} className="pb-1">
                          <InputText
                            controlId="new_password"
                            label="New Password *"
                            type="password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorsField={errors.new_password}
                            touched={touched.new_password}
                            className="w-100 fs-16 mb-3 custom-text-field"
                            InputLabelProps={{
                              className: "fs-15 grey-color",
                            }}
                            value={values.new_password}
                            name="new_password"
                          />
                        </Col>
                      </Row>
                      <Row className="gx-xl-5">
                        <Col sm={6} md={6} lg={3} className="pb-1">
                          <InputText
                            controlId="confirm_password"
                            label="Confirm Password *"
                            type="password"
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            errorsField={errors.confirm_password}
                            touched={touched.confirm_password}
                            className="w-100 fs-16 custom-text-field mb-2"
                            InputLabelProps={{
                              className: "fs-15 grey-color",
                            }}
                            value={values.confirm_password}
                            name="confirm_password"
                          />
                        </Col>
                      </Row>

                      <Stack direction="horizontal" gap={2} className="my-3">
                        <Button
                          variant="primary"
                          className="rounded-lg fs-15 mw-84"
                          onClick={handleSubmit}
                        >
                          Update
                        </Button>
                        <Link
                          to={nameBasedProtectedRoutes.dashborad.path}
                          className="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn"
                        >
                          Cancel
                        </Link>
                      </Stack>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </Card>
        </div>
      </div>

      <Loader isLoading={loading} />
    </>
  );
};
