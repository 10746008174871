import { useEffect, useState, useRef } from "react";
import {
  Button,
  Container,
  Dropdown,
  Image,
  Nav,
  Navbar,
} from "react-bootstrap";
import { FaBell } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import "./header.scss";
import Logo from "../../../assets/images/logo.svg";
import User from "../../../assets/images/user.png";
import storage from "../../../helpers/storage";
import {
  getUserDetails,
  getNotificationList,
} from "../../../services/userService";
import Notification from "../../../components/common/Notification";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

/**
 * Header Sidebar & Toggle Manager
 * @date 4/12/2023 - 12:56:12 PM
 *
 * @param {{ isActiveSidebar: any; toggleSidebarButton: any; }} {
  isActiveSidebar, toggleSidebarButton
}
 * @returns {*}
 */

export const Header = ({ isActiveSidebar, toggleSidebarButton }) => {
  const UserProfile = "https://dummyimage.com/40x40.png";
  const userName = "John Due";
  const preFix = storage.getStoragePrefix();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isNotificationCount, setIsNotificationCount] = useState(0);
  const [title, setTitle] = useState("Dashboard");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenBell, setDropdownOpenBell] = useState(false);
  const [notificationBell, setNotificationBell] = useState(false);


  const clickRef = useRef();
  useOnClickOutside(clickRef, () => setNotificationBell(false));

  const logOut = async () => {
    setLoading(true);
    localStorage.removeItem(`${preFix}token`);
    localStorage.removeItem("user-info");
    navigate("/");
    setLoading(false);
  };
  const MINUTE_MS = 10000; // 60000 Miliseconds is equal to 1 minute

  useEffect(() => {
    const userId = getUserData.id;
    if (userId) {
      getUserDetails().then(
        (resp) => {
          const res = resp?.data;
          storage.setData("user-info", res);
          console.log("res?.unReadCount", resp?.unReadCount);
          setNotificationCount(resp?.unReadCount);
        },
        (error) => {}
      );
    }
    const interval = setInterval(() => {
      const userId = getUserData.id;
      if (userId) {
        getUserDetails().then(
          (resp) => {
            const res = resp?.data;
            storage.setData("user-info", res);
            console.log("res?.unReadCount", resp?.unReadCount);
            setNotificationCount(resp?.unReadCount);
          },
          (error) => {}
        );
      }
    }, MINUTE_MS);
    return () => clearInterval(interval);
  }, []);
  const toggle = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const handleBell = () => {
    setNotificationBell(!notificationBell);
  };

  // Toggle notification list hide and show
  const toggleBell = () => {
    setDropdownOpenBell((prevState) => !prevState);
    // alert(dropdownOpenBell);
    if (!dropdownOpenBell) {
      // Mark Notification as Read
      // alert('read')
      // readNotification();
    }
  };
  useEffect(() => {
    setTitle(storage.getPageTitle ? storage.getPageTitle : "Dashboard");
  });
  const getUserData = storage.getData("user-info");
  return (
    <Navbar bg="" variant="light" className="py-0 px-md-1">
      <Container fluid className="px-3 mh-60">
        <Button
          onClick={toggleSidebarButton}
          variant="link"
          className="align-items-center d-flex justify-content-center navMenuBtn p-0 bg-white rounded-circle h-40 w-40"
        >
          <span
            className={`bg-black d-inline-block menuTrigger position-relative text-center ${
              isActiveSidebar ? "active" : ""
            }`}
          ></span>
        </Button>
        <h1 className="fw-bold h4 mb-0 ms-2 ps-1 text-black">{title}</h1>
        <Link to="/" className="d-none mx-2 mx-md-3 px-1">
          <Image
            className="img-fluid"
            src={Logo}
            alt="Logo"
            width={80}
            height={32}
          />
        </Link>
        <Nav className="ms-auto align-items-center">
          
          {/* <Dropdown isOpen={dropdownOpenBell}
            toggle={toggleBell} className="notificationDropdown me-2">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-notification"
              className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-light p-2"
            >
              
            
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="fs-14 shadow-sm px-3" id="scrollableDiv"  style={{ height: 120, overflow: "auto" }}>
          
            </Dropdown.Menu>
          </Dropdown> */}
          <>
          <div className="notifMain me-2">
          <button type="button" id="dropdown-notification" className="fs-14 fw-semibold text-decoration-none position-relative rounded-circle d-inline-flex align-items-center justify-content-center text-light p-2 btn btn-link" onClick={handleBell}>
            <FaBell size={18} color="#6A3F91" />
          </button>


              {notificationCount != 0 ? <span className="align-items-center badge bg-danger  customBadge d-flex fs-10 fw-semibold justify-content-center position-absolute px-1 rounded-circle top-0">
              {notificationCount}<span className="visually-hidden">unread messages</span>
              </span> : '' }
          
          {notificationBell && <div ref={clickRef} className="notifOpen shadow-sm" id="scrollableDiv"><Notification
                setNotificationBell={setNotificationBell}
                notificationCount={notificationCount}
              /></div> }
              </div>
              </>

          {/* <div className="divider mx-1 mx-md-3 vr opacity-100"></div> */}

          <Dropdown className="profileDropdown ms-md-2 ms-1">
            <Dropdown.Toggle
              variant="link"
              id="dropdown-profile"
              className="border-0 fs-14 fw-semibold text-decoration-none p-0 text-light"
            >
              <Image
                className="object-fit-cover rounded-circle"
                src={
                  getUserData
                    ? getUserData.profile_picture
                      ? getUserData.profile_picture
                      : User
                    : User
                }
                alt=""
                width={30}
                height={30}
              />
              <span className="align-middle d-none d-md-inline-block ms-1 px-2 text-truncate mxw-90 fs-15 text-black fw-normal pe-1">
                {getUserData?.name}
              </span>
              {/*<FaAngleDown size={16} />*/}
            </Dropdown.Toggle>
            <Dropdown.Menu align="end" className="fs-14 shadow-sm">
              <Dropdown.Item as={Link} to="/app/profile" className="fw-medium">
                Profile
              </Dropdown.Item>
              <Dropdown.Item
                as={Link}
                to="/app/change-password"
                className="fw-medium"
              >
                Change Password
              </Dropdown.Item>
              <Dropdown.Divider className="mb-1" />
              <Dropdown.Item className="fw-medium" onClick={logOut}>
                Logout
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Nav>
      </Container>
    </Navbar>
  );
};
