import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Form } from "react-bootstrap";

/**
 * Custom Common Select Field
 * @date 5/5/2023 - 12:34:21 PM
 *
 * @param {{ label: any; options: any; value: any; onChange: any; minWidth: any; }} { label, options, value, onChange, minWidth }
 * @returns {*}
 */

export const CustomSelect = ({
  label,
  options,
  value,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  minWidth,
  placeholder,
  controlId,
  name,
}) => {
  // const handleSelectChange = (event) => {
  //   onChange(event.target.value);
  // };
  return (
    <FormControl variant="standard" fullWidth error={!!errorsField && touched}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        labelId="select-label"
        value={value}
        onChange={handleChange}
        label={label}
        name={name}
        onBlur={handleBlur}
      >
        <MenuItem value="">{placeholder}</MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className="red">{errorsField}</FormHelperText>
    </FormControl>
  );
};
