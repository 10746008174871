import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Form, Row, Stack } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CustomSelect } from "../../../components/common/CustomSelect";
import { Formik } from "formik";
import InputText from "../../../components/common/InputText";
import { addFranchiseSchema } from "../../../validations/Franchise";
import { toast } from "react-toastify";
import {
  AddEntitiyMethod,
  EditEntitiyMethod,
  getAllCategory,
  getAllCity,
  getAllCountry,
  getAllState,
  yearData,
} from "../../../helpers/helpersMethods";
import Loader from "../../../components/common/Loader";
import useDynamicFields from "../../../components/common/useDynamicFields";
import { uploadMedia } from "../../../services/userService";
import {
  addFranchise,
  franchiseMediaDelete,
  getFranchiseDetails,
  updateFranchise,
} from "../../../services/franchiseService";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import { FaTimes } from "react-icons/fa";
import { TextField } from "@mui/material";

const MAX_MEDIA_ALLOWED = 5; // Maximum number of images allowed
const MAX_VIDEO_ALLOWED = 1; // Maximum number of images allowed
const MAX_IMAGE_SIZE_IN_BYTES = 10 * 1024 * 1024; // 500KB in bytes
const MAX_VIDEO_SIZE_IN_BYTES = 50 * 1024 * 1024; // 50MB in bytes
const ALLOWED_VIDEO_TYPES = ["video/mp4"];
const ALLOWED_IMAGE_TYPES = ["image/jpeg", "image/png"];
export const EditFranchise = () => {
  const { id } = useParams();
  const [count, setCount] = useState(1);
  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState();
  const [selectedImagesMedia, setSelectedImagesMedia] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [industryList, setIndustryList] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const fileInputRef = useRef(null);
  const fileVideoInputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [isFormSubmitted, setFormSubmitted] = useState(false);
  const [selectedVideos, setSelectedVideos] = useState([]);
  const [getdetails, setGetDetails] = useState(false);
  const [awardData, setAwardData] = useState([{}]);
  const [relevantFieldsData, setRelevantFieldsData] = useState([{}]);
  const [documentData, setDocumentData] = useState([{}]);

  const [data, setData] = useState();
  const [yearOptions, setYearOptions] = useState(yearData());
  const fieldsConfig = [
    {
      type: "text",
      label: "Award Name",
      name: "award_name",
      validation: (value) => {
        // Add custom validation logic for "award" field here
        // Return an error message string if the value is not valid, or an empty string if it's valid
        if (!value) {
          return "Award Name is required.";
        }
        return ""; // Empty string means validation passed
      },
      isRequired: true,
    },
    {
      type: "text",
      label: "Recognition Company",
      name: "award_company_name",
      validation: (value) => {
        // Add custom validation logic for "recognitionCompany" field here
        // Return an error message string if the value is not valid, or an empty string if it's valid
        if (!value) {
          return "Recognition Company is required.";
        }
        return ""; // Empty string means validation passed
      },
      isRequired: false, // You can set this to true if this field is also mandatory
    },
    // Add more field configurations here as needed
  ];
  const fieldsWebConfig = [
    {
      type: "text",
      label: "Relevant Link",
      name: "relevant_link",
      validation: (value) => {
        // Add custom validation logic for "award" field here
        // Return an error message string if the value is not valid, or an empty string if it's valid
        if (!value) {
          return "Relevant Link  is required.";
        }
        return ""; // Empty string means validation passed
      },
      isRequired: true,
    },
  ];
  const fieldsDocumentConfig = [
    {
      type: "file",
      label: "Document",
      name: "document_file_name",
      validation: (value) => {
        if (!value) {
          return "Please select file";
        }
        return ""; // Empty string means validation passed
      },
      isRequired: true,
      acceptedFileTypes: ".pdf,.doc,.docx",
      maxFileSize: 5 * 1024 * 1024, // 5MB in bytes
    },
    {
      type: "text",
      label: "Document Name",
      name: "document_name",
      validation: (value) => {
        if (!value) {
          return "Document Name is required.";
        }
        return ""; // Empty string means validation passed
      },
      isRequired: true,
    },
  ];
  useEffect(() => {
    setLoading(true);
    if (id) {
      getFranchiseDetails(id).then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);
            fetchCountryChange(res.country_id);
            handleStateChange(res.state_id);
            setAwardData(res.franchise_award);
            setSelectedImages({ file_path: res.logo_link });
            setSelectedImagesMedia(res.franchise_media);
            setRelevantFieldsData(res.franchise_link);
            if (
              Array.isArray(res.franchise_document) &&
              res.franchise_document.length > 0
            ) {
              // Iterate through the array and swap the keys "document_file" and "document_file_name"
              res.franchise_document.forEach((item) => {
                const temp = item.document_file;
                item.document_file = item.document_file_name;
                item.document_file_name = temp;
              });
            }
            // Assuming setDocumentData function updates the data object
            setDocumentData(res.franchise_document);
            setGetDetails(true);
            console.log(res.franchise_document);
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.franchise.path);
          }
        }
      );
    }
  }, [id]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allCountryData = await getAllCountry();
        setCountryList(allCountryData);
      } catch (error) {
        // Handle any errors that occurred during the data retrieval
      }
    };
    const industryData = async () => {
      try {
        const allIndustryData = await getAllCategory("industry");
        setIndustryList(allIndustryData);
      } catch (error) {
        // Handle any errors that occurred during the data retrieval
      }
    };
    fetchData();
    industryData();
  }, []);

  const fetchCountryChange = async (countryId) => {
    setLoading(true);
    try {
      const allStateData = await getAllState(countryId);
      setStateList(allStateData);
      setLoading(false);
    } catch (error) {
      // Handle any errors that occurred during the data retrieval
      setLoading(false);
    }
  };
  const handleCountryChange = async (countryId) => {
    setLoading(true);
    try {
      const allStateData = await getAllState(countryId);
      setStateList(allStateData);
      setCityList([]);
      setLoading(false);
    } catch (error) {
      // Handle any errors that occurred during the data retrieval
      setLoading(false);
    }
  };
  const handleStateChange = async (stateId) => {
    setLoading(true);
    try {
      const allCityData = await getAllCity(stateId);
      setCityList(allCityData);
      setLoading(false);
    } catch (error) {
      // Handle any errors that occurred during the data retrieval
      setLoading(false);
    }
  };
  const handleBrowseClick = () => {
    // Programmatically trigger the file input click event
    fileInputRef.current.click();
  };
  const handleVideoBrowseClick = () => {
    // Programmatically trigger the file input click event
    fileVideoInputRef.current.click();
  };

  const handleImageChange = async (event, setFieldValue, values, type) => {
    const { target } = event;
    const { files } = target;
    const imageFile = files[0];

    if (!imageFile) {
      toast.error("Please select an image.");
      return;
    }

    // Validate the image type and size
    if (imageFile.size > MAX_IMAGE_SIZE_IN_BYTES) {
      toast.error("The image size exceeds the maximum limit of 10MB.");
      target.value = ""; // Clear the selected file
      return;
    }

    const acceptedTypes = [".jpeg", ".jpg", ".png"];
    const fileType = imageFile.name
      .substring(imageFile.name.lastIndexOf("."))
      .toLowerCase();
    if (!acceptedTypes.includes(fileType)) {
      toast.error("Only JPEG and PNG images are allowed.");
      target.value = ""; // Clear the selected file
      return;
    }
    const formData = new FormData();
    formData.append("file", imageFile);
    setSelectedImages("");
    setLoading(true);
    uploadMedia(formData).then(
      (response) => {
        const res = response?.data;
        setTimeout(() => {
          setLoading(false);
          if (response?.statusCode === 200) {
            setFieldValue(type, res.file_name);
            setSelectedImages({
              file_name: res.file_name,
              file_path: res.file_path,
            });
          }
          if (res) {
          }
        }, 1000);
      },
      (error) => {
        setLoading(false);
        // toast.error(error.response.data.msg);
      }
    );
    target.value = "";
    console.log(selectedImages);
  };

  const handleMediaChange = async (event, handleChange, values) => {
    const { target } = event;
    const { files } = target;
    const mediaFile = files[0];
    console.log("de", selectedImagesMedia);
    let mediaType = "image";
    if (!mediaFile) {
      toast.error("Please select an media.");
      return;
    }

    // Validate the media type and size
    if (mediaFile.type === "video/mp4") {
      mediaType = "video";
      // Handling video file
      if (mediaFile.size > 50 * 1024 * 1024) {
        toast.error("The video size exceeds the maximum limit of 50MB.");
        target.value = ""; // Clear the selected file
        return;
      }
      if (selectedImagesMedia.some((media) => media.media_type === "video")) {
        toast.error("You can only upload one video.");
        target.value = ""; // Clear the selected file
        return;
      }
    } else if (mediaFile.type.startsWith("image/")) {
      // Handling image file
      if (mediaFile.size > 10 * 1024 * 1024) {
        toast.error("The image size exceeds the maximum limit of 10MB.");
        target.value = ""; // Clear the selected file
        return;
      }
    } else {
      toast.error("Only MP4 videos and images (JPEG, JPG, PNG) are allowed.");
      target.value = ""; // Clear the selected file
      return;
    }

    if (selectedImagesMedia.length >= 5) {
      toast.error("You can only upload up to 5 media files.");
      target.value = ""; // Clear the selected file
      return;
    }
    console.log(selectedImagesMedia);
    const formData = new FormData();
    formData.append("file", mediaFile);
    formData.append("file_type", mediaType);
    setLoading(true);
    uploadMedia(formData)
      .then((response) => {
        const res = response?.data;
        setTimeout(() => {
          setLoading(false);
          if (response?.statusCode === 200) {
            setSelectedImagesMedia((prevSelectedImages) => [
              ...prevSelectedImages,
              {
                file_name: res.file_name,
                media_file: res.file_name,
                media_type: mediaType,
                media_file_name: res.file_path,
              },
            ]);
            console.log("add", selectedImagesMedia);
          }
          if (res) {
            // Handle additional logic if needed
          }
        }, 1000);
      })
      .catch((error) => {
        setLoading(false);
        // Handle error if needed
        // toast.error(error.response.data.msg);
      });

    target.value = "";
  };

  const getSelectedImageNames = (values) => {
    return (
      <div className="thumb">
        {selectedImages && (
          <>
            <div className="thumbInner">
              <img src={selectedImages.file_path} />
              {/* <span>{image.file.name}</span> */}
              <Button
                variant="danger"
                size="sm"
                onClick={() => handleRemoveImage()}
              >
                <FaTimes size={9} color="#fff" />
              </Button>
            </div>
          </>
        )}
      </div>
    );
  };
  const getSelectedVideoNames = () => {
    console.log("testtt", selectedImagesMedia);
    return selectedImagesMedia.map((media, index) => (
      <div key={index} className="thumb">
        {media.media_file_name && (
          <>
            <div className="thumbInner">
              {media.media_type == "image" ? (
                <img src={media.media_file_name} alt={`Thumbnail ${index}`} />
              ) : (
                <video controls>
                  <source src={media.media_file_name} type="video/mp4" />
                </video>
              )}
              {/* <span>{video.file.name}</span> */}

              <Button
                variant="danger"
                size="sm"
                onClick={() =>
                  media.id
                    ? handleRemoveVideodb(media.id, index)
                    : handleRemoveVideo(index)
                }
              >
                <FaTimes size={9} color="#fff" />
              </Button>
            </div>
          </>
        )}
      </div>
    ));
  };

  const handleRemoveImage = () => {
    setSelectedImages("");
  };
  const handleRemoveVideo = (index) => {
    setSelectedImagesMedia((prevSelectedVideos) => {
      // Create a new array and exclude the video at the specified index
      const updatedVideos = prevSelectedVideos.filter((_, i) => i !== index);
      return updatedVideos;
    });
  };
  const handleRemoveVideodb = (id, index) => {
    setSelectedImagesMedia((prevSelectedVideos) => {
      const updatedVideos = prevSelectedVideos.filter((_, i) => i !== index);
      return updatedVideos;
    });
    if (id) {
      const type = "media";
      franchiseMediaDelete(id, type).then(
        (resp) => {
          setLoading(false);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.franchise.path);
          }
        }
      );
    }
  };

  //const { renderDynamicFields, dynamicFields } = useDynamicFields(fieldsConfig, 10, [{}]);
  const {
    renderDynamicFields: renderAwardsFields,
    dynamicFields: awardFields,
  } = useDynamicFields(
    fieldsConfig,
    10,
    awardData,
    isFormSubmitted,
    setLoading,
    "edit"
  );
  const {
    renderDynamicFields: renderRelevantFields,
    dynamicFields: relevantFields,
  } = useDynamicFields(
    fieldsWebConfig,
    10,
    relevantFieldsData,
    isFormSubmitted,
    setLoading,
    "edit"
  );

  const {
    renderDynamicFields: renderUploadFields,
    dynamicFields: documentUploadFields,
  } = useDynamicFields(
    fieldsDocumentConfig,
    10,
    documentData,
    isFormSubmitted,
    setLoading,
    "edit"
  );

  const handleSubmit = (values, actions) => {
    setFormSubmitted(true);

    // Validate dynamic fields
    const hasEmptyLinkFields = relevantFields.some((field) => {
      if (field.relevant_link && field.relevant_link.length > 0) {
        const urlPattern =
          /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(\/\S*)?$/;
        if (!urlPattern.test(field.relevant_link)) {
          return true; // Invalid URL, return true
        }
      }
      return false; // Valid link
    });

    if (hasEmptyLinkFields) {
      toast.error("Please fill in all relevant Links with valid URLs.");
      actions.setSubmitting(false);
      return;
    }

    const hasEmptyDynamicFields = awardFields.some((field) => {
      // Modify the condition based on your validation logic
      return (
        (field.award_name && !field.award_company_name) ||
        (!field.award_name && field.award_company_name)
      );
    });
    if (hasEmptyDynamicFields) {
      toast.error("Please fill in all award and recognition company fields.");
      actions.setSubmitting(false);
      return;
    }

    const hasEmptyDynamicFieldsDocumentFields = documentUploadFields.some(
      (field) => {
        // Modify the condition based on your validation logic
        return (
          (field.document_file_name && !field.document_name) ||
          (!field.document_file_name && field.document_name)
        );
      }
    );
    if (hasEmptyDynamicFieldsDocumentFields) {
      toast.error("Please fill in all the document category fields.");
      actions.setSubmitting(false);
      return;
    }

    if (selectedImages.length === 0) {
      toast.error("Please select logo image.");
      return;
    }
    const mergedValues = {
      award_json: [...awardFields],
      relevant_link_json: [...relevantFields],
      document_name_json: [...documentUploadFields],
      logo: selectedImages ? selectedImages.file_name : "",
      media_json: [...selectedImagesMedia],
      ...values, // Assuming the form's static fields are present in the 'values' object
      total_units: values.total_units.toString(),
      id: id,
    };
    mergedValues.award_json = JSON.stringify(mergedValues.award_json);
    mergedValues.relevant_link_json = JSON.stringify(
      mergedValues.relevant_link_json
    );
    mergedValues.document_name_json = JSON.stringify(
      mergedValues.document_name_json
    );
    mergedValues.media_json = JSON.stringify(mergedValues.media_json);
    delete mergedValues.award;
    delete mergedValues.documentName;
    delete mergedValues.uploadLogo;
    delete mergedValues.uploadVideo;
    delete mergedValues.recognitionCompany;
    delete mergedValues.relevantLink;

    actions.setSubmitting(false);

    if (mergedValues) {
      EditEntitiyMethod(
        setLoading,
        updateFranchise,
        id,
        mergedValues,
        "",
        nameBasedProtectedRoutes.franchise.path,
        navigate
      );
    }
  };
  return (
    <>
      {getdetails && data && countryList && industryList ? (
        <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
          <div className="flex-grow-1 pageContent position-relative pt-4">
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fw-medium">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <svg
                        id="Franchise_3_"
                        data-name="Franchise (3)"
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                      >
                        <path
                          id="Path_28704"
                          data-name="Path 28704"
                          d="M49.758,320.457h9.55v1.758h1.758v-1.758h9.551v1.758h1.758v-2.637a.879.879,0,0,0-.879-.879H61.066V317H59.308v1.7H48.879a.879.879,0,0,0-.879.879v2.637h1.758Z"
                          transform="translate(-45.188 -298.426)"
                          fill="#fff"
                        />
                        <path
                          id="Path_28705"
                          data-name="Path 28705"
                          d="M392.5,436h-5.625a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879H392.5a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,392.5,436Z"
                          transform="translate(-363.383 -410.453)"
                          fill="#fff"
                        />
                        <path
                          id="Path_28706"
                          data-name="Path 28706"
                          d="M6.5,436H.879a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879H6.5a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,6.5,436Z"
                          transform="translate(0 -410.453)"
                          fill="#fff"
                        />
                        <path
                          id="Path_28707"
                          data-name="Path 28707"
                          d="M199.495,436H193.87a.879.879,0,0,0-.879.879v2.7a.879.879,0,0,0,.879.879h5.625a.879.879,0,0,0,.879-.879v-2.7A.879.879,0,0,0,199.495,436Z"
                          transform="translate(-181.683 -410.453)"
                          fill="#fff"
                        />
                        <path
                          id="Path_28708"
                          data-name="Path 28708"
                          d="M117.862,1.758h.059V16.816h4.043V11.426a.879.879,0,0,1,.879-.879h4.571a.879.879,0,0,1,.879.879v5.391h4.043V1.758h.059a.879.879,0,0,0,0-1.758H117.862a.879.879,0,0,0,0,1.758Zm4.453,1.758h5.626a.879.879,0,0,1,0,1.758h-5.626a.879.879,0,0,1,0-1.758Zm0,3.516h5.626a.879.879,0,0,1,0,1.758h-5.626a.879.879,0,0,1,0-1.758Z"
                          transform="translate(-110.129)"
                          fill="#fff"
                        />
                        <path
                          id="Path_28709"
                          data-name="Path 28709"
                          d="M231.983,210H234.8v4.512h-2.814Z"
                          transform="translate(-218.39 -197.695)"
                          fill="#fff"
                        />
                      </svg>
                    </div>
                    Edit Franchise
                  </h5>
                </Stack>
              </div>
              <Formik
                validationSchema={addFranchiseSchema}
                initialValues={{
                  name: data ? data.name : "",
                  indus_category_id: data ? data.indus_category_id : "",
                  uploadLogo: "",
                  uploadVideo: "",
                  description: data ? data.description : "",
                  total_units: data ? data.total_units : "",
                  year_founded: data ? data.year_founded : "",
                  founder_name: data ? data.founder_name : "",
                  franchise_since: data ? data.franchise_since : "",
                  verterned_own_unit: data ? data.verterned_own_unit : "",
                  employees: data ? data.employees : "",
                  annual_revenue: data ? data.annual_revenue : "",
                  net_worth: data ? data.net_worth : "",
                  address: data ? data.address : "",
                  city_id: data ? data.city_id : "",
                  state_id: data ? data.state_id : "",
                  country_id: data ? data.country_id : "",
                  award: "",
                  recognitionCompany: "",
                  relevantLink: "",
                  documentName: "",
                  logo: data ? data.logo : "",
                }}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  setFormSubmitted(true);
                  handleSubmit(values, actions);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                  setFieldValue,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <div className="flex-grow-1 d-flex flex-column px-1">
                      <div className="p-3 d-flex flex-column h-100">
                        <div className="d-flex flex-column flex-grow-1 theme-from">
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="name"
                                label="Franchise Name *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.name}
                                touched={touched.name}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.name}
                                name="name"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                controlId="indus_category_id"
                                label="Industry Type *"
                                placeholder="Select industry"
                                options={industryList}
                                touched={touched.indus_category_id}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.indus_category_id}
                                value={values.indus_category_id}
                                name="indus_category_id"
                              />
                            </Col>
                          </Row>

                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="position-relative customUpload">
                                <input
                                  ref={fileInputRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleImageChange(
                                      event,
                                      setFieldValue,
                                      values,
                                      "logo"
                                    )
                                  }
                                />
                                <InputText
                                  controlId="logo"
                                  label="Upload Logo *"
                                  className="w-100 fs-16 mb-4 custom-text-field"
                                  InputLabelProps={{
                                    className: "fs-15 grey-color",
                                  }}
                                  value={selectedImages ? values.logo : ""}
                                  name="logo"
                                  errorsField={errors.logo}
                                  touched={touched.logo}
                                  type="text"
                                  disabled="disabled"
                                />
                                <button
                                  className="mw-84 fs-12 fw-medium text-black position-absolute"
                                  onClick={handleBrowseClick}
                                  type="button"
                                >
                                  Browse
                                </button>
                                {getSelectedImageNames()}
                                <div className="thumb"></div>
                              </div>
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <div className="position-relative customUpload">
                                <input
                                  ref={fileVideoInputRef}
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={(event) =>
                                    handleMediaChange(
                                      event,
                                      handleChange,
                                      values
                                    )
                                  }
                                />
                                <InputText
                                  controlId="uploadVideo"
                                  label="Upload Media"
                                  className="w-100 fs-16 mb-4 custom-text-field"
                                  InputLabelProps={{
                                    className: "fs-15 grey-color",
                                  }}
                                  value={values.uploadVideo}
                                  name="uploadVideo"
                                  type="text"
                                  disabled="disabled"
                                />
                                <button
                                  className="mw-84 fs-12 fw-medium text-black position-absolute"
                                  onClick={handleVideoBrowseClick}
                                  type="button"
                                >
                                  Browse
                                </button>
                                <div>{getSelectedVideoNames()}</div>
                              </div>
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={12} md={12} lg={10} className="pb-1">
                              <InputText
                                controlId="description"
                                label="Description *"
                                type="textarea"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.description}
                                touched={touched.description}
                                className="w-100 fs-16 mb-4 custom-text-field customTextarea"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.description}
                                multiline
                                name="description"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="total_units"
                                label="Total Units"
                                type="number"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.total_units}
                                touched={touched.total_units}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.total_units}
                                name="total_units"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                label="Year Founded *"
                                placeholder="Select Year"
                                options={yearOptions}
                                touched={touched.year_founded}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.year_founded}
                                value={values.year_founded}
                                name="year_founded"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="founder_name"
                                label="Founder Name *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.founder_name}
                                touched={touched.founder_name}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.founder_name}
                                name="founder_name"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                label="Started Franchising"
                                placeholder="Select Year"
                                options={yearOptions}
                                touched={touched.franchise_since}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.franchise_since}
                                value={values.franchise_since}
                                name="franchise_since"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="verterned_own_unit"
                                label="CEO"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.verterned_own_unit}
                                touched={touched.verterned_own_unit}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.verterned_own_unit}
                                name="verterned_own_unit"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="employees"
                                label="Employees *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.employees}
                                touched={touched.employees}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.employees}
                                name="employees"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="annual_revenue"
                                label="Min. Investment *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.annual_revenue}
                                touched={touched.annual_revenue}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.annual_revenue}
                                name="annual_revenue"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <InputText
                                controlId="net_worth"
                                label="Required Net Worth *"
                                type="text"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.net_worth}
                                touched={touched.net_worth}
                                className="w-100 fs-16 mb-4 custom-text-field"
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.net_worth}
                                name="net_worth"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={12} md={12} lg={10} className="pb-1">
                              <InputText
                                controlId="address"
                                label="Address *"
                                type="textarea"
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                errorsField={errors.address}
                                touched={touched.address}
                                className="w-100 fs-16 mb-4 custom-text-field customTextarea"
                                multiline
                                InputLabelProps={{
                                  className: "fs-15 grey-color",
                                }}
                                value={values.address}
                                name="address"
                              />
                            </Col>
                          </Row>
                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                controlId="country_id"
                                label="Country *"
                                placeholder="Select Country"
                                options={countryList}
                                touched={touched.country_id}
                                handleBlur={handleBlur}
                                handleChange={(event) => {
                                  const selectedValue = event.target.value;
                                  values.state_id = "";
                                  values.city_id = "";
                                  setSelectedValue(selectedValue); // Set the selected value (not necessary if not used elsewhere)
                                  handleCountryChange(selectedValue); // Call handleStateChange with the selected country ID
                                  setFieldValue("country_id", selectedValue);
                                }}
                                errorsField={errors.country_id}
                                value={values.country_id}
                                name="country_id"
                              />
                            </Col>
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                controlId="state_id"
                                label="State *"
                                placeholder="Select State"
                                options={stateList}
                                touched={touched.state_id}
                                handleBlur={handleBlur}
                                errorsField={errors.state_id}
                                value={values.state_id}
                                handleChange={(event) => {
                                  const selectedValue = event.target.value;
                                  setSelectedValue(selectedValue); // Set the selected value (not necessary if not used elsewhere)
                                  handleStateChange(selectedValue); // Call handleStateChange with the selected country ID
                                  setFieldValue("state_id", selectedValue);
                                }}
                                name="state_id"
                              />
                            </Col>
                          </Row>

                          <Row className="gx-xl-5">
                            <Col sm={6} md={6} lg={5} className="pb-1">
                              <CustomSelect
                                label="City *"
                                placeholder="Select City"
                                options={cityList}
                                touched={touched.city_id}
                                handleBlur={handleBlur}
                                handleChange={handleChange}
                                errorsField={errors.city_id}
                                value={values.city_id}
                                name="city_id"
                              />
                            </Col>
                          </Row>
                          <h5 className="mb-0 mt-4 fw-medium fs-18 text-black">
                            Awards
                          </h5>
                          <div>{renderAwardsFields()}</div>

                          <h5 className="mb-0 mt-4 fw-medium fs-18 text-black">
                            Relevant Links
                          </h5>

                          <div>{renderRelevantFields()}</div>

                          <h5 className="mb-0 mt-4 fw-medium fs-18 text-black">
                            Upload Document Category
                          </h5>
                          <div>{renderUploadFields()}</div>

                          <Stack
                            direction="horizontal"
                            gap={2}
                            className="my-3"
                          >
                            <Button
                              variant="primary"
                              className="rounded-lg fs-15 mw-84"
                              type="submit"
                            >
                              Update
                            </Button>
                            <Link
                              to={nameBasedProtectedRoutes.franchise.path}
                              className="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn"
                            >
                              Cancel
                            </Link>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}
      <Loader isLoading={loading} />
    </>
  );
};
