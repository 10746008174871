
import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { nameBasedRoutes } from "../../../router/public"
import { toast } from "react-toastify"
import { privacyPolicy } from "../../../services/cmsService"
import Loader from "../../../components/common/Loader"
import { Button, Card, Col, Row, Stack, Image } from 'react-bootstrap';
import Logo from '../../../assets/images/logo.svg';

export const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const page = 'privacy-policy-web';
  const [data, setData] = useState();
  useEffect(() => {
    setLoading(true);
    privacyPolicy(page).then(
      (response) => {
        setLoading(false);
        const res = response?.data;
        if (response?.statusCode === 200) {
          setData(res.page_detail);
        }

      },
      (error) => {
        setLoading(false);
      }
    );

  }, []);


  return (
    <>
      {data ? (

        <>
        <div className="py-3 text-left regularbg">
          <div className="container">
            <div className="logoRegular"><Image src={Logo} alt="logo-image" width={184} height={62} /></div>
          </div>        
        </div>

        <div className="container py-5">
          <div className="flex-grow-1 overflow-auto pageContent pb-3 privacyContent">
            <h2 className="mb-5"><strong>{data.title}</strong></h2>
            <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
          </div>
        </div>


        </>

        




      ) : ""}
      <Loader isLoading={loading} />
    </>
  )
}
