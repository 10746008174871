import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Image, Row, Stack } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { CustomTextField } from "../../components/common/CustomTextField";
import { FaImage, FaUserAlt } from "react-icons/fa";
import { Formik } from "formik";
import { profileSchema } from "../../validations/Profile";
import InputText from "../../components/common/InputText";
import Loader from "../../components/common/Loader";
import { changeProfile, getUserDetails } from "../../services/userService";
import storage from "../../helpers/storage";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../router/protected";

export const Profile = () => {
  // Custom Select handling
  storage.setPageTitle("My Account");
  const [selectedValue, setSelectedValue] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const UserProfile = "https://dummyimage.com/300x300.png";
  const [selectedFile, setSelectedFile] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getUserDetails().then(
      (resp) => {
        setLoading(false);
        const res = resp?.data;
        setImageUrl("");
        setSelectedFile(false);
        setData(res);
        if (res.profile_picture) {
          setImageUrl(data.profile_picture);
        }
      },
      (error) => {
        setLoading(false);
      }
    );
  }, []);
  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    const allowedTypes = ["image/jpeg", "image/png"];
    const maxFileSize = 10 * 1024 * 1024; // 10MB in bytes

    if (event.target.files && event.target.files.length > 0) {
      if (!allowedTypes.includes(file.type)) {
        await toast.error("Only JPG or PNG files are allowed");
        return;
      }
      if (file.size > maxFileSize) {
        await toast.error("File size is too large, maximum file size is 10MB");
        return;
      }
      setSelectedFile(event.target.files[0]);
    }

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
    }
  };

  const submitForm = (value) => {
    setLoading(true);
    value.profile_picture = selectedFile ? selectedFile : null;
    changeProfile(value).then(
      (response) => {
        const res = response?.data;
        setTimeout(() => {
          setLoading(false);
          if (response?.statusCode === 200) {
            toast.success(response.msg);
          }
          if (res) {
            const getUserData = storage.getData("user-info");
            setImageUrl(res.profile_picture);
            storage.setData("user-info", res);
            navigate(nameBasedProtectedRoutes.dashborad.path);
          }
        }, 1000);
      },
      (error) => {
        setLoading(false);
        toast.error(error.response.data.msg);
      }
    );
  };

  return (
    <>
      {data ? (
        <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
          <div className="flex-grow-1 pageContent position-relative pt-4">
            <Card className="h-100 bg-white shadow border-0 theme-card-cover">
              <div className="theme-card-header px-1">
                <Stack
                  direction="horizontal"
                  gap={2}
                  className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
                >
                  <h5 className="mb-0 position-relative fw-medium fs-18">
                    <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                      <span className="theme-icon-box-inner">
                        {/* <FaUserAlt size={30} /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="31.748"
                          height="31.748"
                          viewBox="0 0 31.748 31.748"
                        >
                          <g
                            id="Group_21823"
                            data-name="Group 21823"
                            transform="translate(-2 -2)"
                          >
                            <path
                              id="Path_28742"
                              data-name="Path 28742"
                              d="M8.111,25.97a16.564,16.564,0,0,1,4.524-2.441,15.813,15.813,0,0,1,10.477,0,16.564,16.564,0,0,1,4.524,2.441A12.27,12.27,0,0,0,29.8,22.279a12.443,12.443,0,0,0,.774-4.405,12.242,12.242,0,0,0-3.711-8.989,12.242,12.242,0,0,0-8.989-3.711A12.242,12.242,0,0,0,8.885,8.885a12.242,12.242,0,0,0-3.711,8.989,12.443,12.443,0,0,0,.774,4.405A12.268,12.268,0,0,0,8.111,25.97Zm9.763-6.508a5.489,5.489,0,0,1-5.556-5.556A5.489,5.489,0,0,1,17.874,8.35a5.489,5.489,0,0,1,5.556,5.556,5.489,5.489,0,0,1-5.556,5.556Zm0,14.287a15.458,15.458,0,0,1-6.191-1.25A15.849,15.849,0,0,1,3.25,24.065,15.458,15.458,0,0,1,2,17.874a15.458,15.458,0,0,1,1.25-6.191A15.849,15.849,0,0,1,11.683,3.25,15.458,15.458,0,0,1,17.874,2a15.458,15.458,0,0,1,6.191,1.25A15.849,15.849,0,0,1,32.5,11.683a15.458,15.458,0,0,1,1.25,6.191,15.458,15.458,0,0,1-1.25,6.191A15.849,15.849,0,0,1,24.065,32.5,15.458,15.458,0,0,1,17.874,33.748Z"
                              fill="#fff"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                    Update Profile
                  </h5>
                </Stack>
              </div>
              <Formik
                validationSchema={profileSchema}
                initialValues={{
                  name: data ? data.name : "",
                  email: data ? data.email : "",
                }}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  const { email, ...formData } = values;
                  submitForm(formData);
                }}
              >
                {({
                  handleSubmit,
                  handleChange,
                  handleBlur,
                  values,
                  touched,
                  isValid,
                  errors,
                }) => (
                  <div className="flex-grow-1 d-flex flex-column px-1">
                    <div className="p-3 d-flex flex-column h-100">
                      <div className="d-flex flex-column flex-grow-1 theme-from">
                        <Row className="gx-xl-5">
                          <Col
                            sm={12}
                            md={12}
                            lg={12}
                            className="pt-4 mb-3 pb-1 d-flex align-items-start"
                          >
                            <div className="height-100 width-100 d-flex justify-content-center align-items-center profilebg rounded-2 me-3">
                              {/* <Image
                            className="object-fit-cover"
                            src={UserProfile}
                            alt="Profile Image"
                            
                            /> */}
                              {!selectedFile && !data.profile_picture && (
                                <FaImage size={32} />
                              )}

                              {selectedFile && (
                                <Image
                                  className="object-fit-cover"
                                  src={imageUrl}
                                  alt="Profile Image"
                                />
                              )}

                              {!selectedFile && data.profile_picture && (
                                <Image
                                  className="object-fit-cover"
                                  src={data.profile_picture}
                                  alt="Profile Image"
                                />
                              )}
                            </div>
                            <div className="d-flex flex-column">
                              <label className="position-relative mb-1">
                                <span
                                  variant="contained"
                                  component="label"
                                  className="text-decoration-underline fs-13 link-color-blue"
                                >
                                  Upload File
                                </span>
                                {/* <input
                                type="file"
                                className="opacity-0 position-absolute start-0 w-64"
                                id="browse"
                              /> */}
                                <Form.Control
                                  type="file"
                                  className="bottom-0 end-0 opacity-0 position-absolute start-0 top-0 z-1"
                                  onChange={handleFileChange}
                                />
                              </label>
                              <div className="d-inline-block">
                                <p className="m-0 fs-12 labelColor">
                                  Max. size: 10MB
                                </p>
                                <p className="m-0 fs-12 labelColor">
                                  Format: jpg, png
                                </p>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={3} className="mt-2 mb-1 pb-1">
                            <InputText
                              controlId="name"
                              label="Name *"
                              type="text"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errorsField={errors.name}
                              touched={touched.name}
                              className="w-100 fs-16 mb-3 custom-text-field"
                              InputLabelProps={{
                                className: "fs-15 grey-color",
                              }}
                              value={values.name}
                              name="name"
                            />
                          </Col>
                        </Row>
                        <Row className="gx-xl-5">
                          <Col sm={6} md={6} lg={3} className="mb-0 pb-1">
                            <InputText
                              controlId="email"
                              label="Email"
                              type="text"
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              errorsField={errors.email}
                              touched={touched.email}
                              className="w-100 fs-16 mb-2 custom-text-field"
                              InputLabelProps={{
                                className: "fs-15 grey-color",
                              }}
                              value={values.email}
                              name="email"
                              disabled={true}
                            />
                          </Col>
                        </Row>

                        <Stack direction="horizontal" gap={3} className="my-3">
                          <Button
                            variant="primary"
                            className="rounded-lg fs-15 mw-84"
                            onClick={handleSubmit}
                          >
                            Update
                          </Button>
                          <Link
                            to={nameBasedProtectedRoutes.dashborad.path}
                            className="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn"
                          >
                            Cancel
                          </Link>
                        </Stack>
                      </div>
                    </div>
                  </div>
                )}
              </Formik>
            </Card>
          </div>
        </div>
      ) : (
        ""
      )}
      <Loader isLoading={loading} />
    </>
  );
};
