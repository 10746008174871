import "./style.scss"
import Logo from "../../assets/images/logo-img.svg"
import AppStore from "../../assets/images/appstore_white.svg"
import PlayStore from "../../assets/images/playstore_white.svg"
import PromoPhone from "../../assets/images/promo-phone.png"
import PromoMobile from "../../assets/images/promo-mobile-view.png"
import ScreenBottom from "../../assets/images/screen-bottom.png"
import AppStoreBlack from "../../assets/images/appstore_white.png"
import PlayStoreBlack from "../../assets/images/playstore_white.png"
import InstagramLogo from "../../assets/images/Instagram_logo.png"
import YoutubeLogo from "../../assets/images/youtube.png"
import { nameBasedRoutes } from "../../router/public";
import { Link, useNavigate } from "react-router-dom";
import storage from "../../helpers/storage"
import { nameBasedProtectedRoutes, protectedRoutes } from "../../router/protected"
import { useEffect } from "react"


export function LandingPage() {
    const navigate = useNavigate();
    let yeatStr = new Date().getFullYear();
    useEffect(() => {
     if(storage.getToken())
     {
        navigate(nameBasedProtectedRoutes.dashborad.path);
     }
    }, [])
    
    const mainRoutes = storage.getToken() != null ? protectedRoutes : [];
    return (
            <div className="landingMain">
                <div className="topPortion">
                    <div className="header py-5">
                        <div className="container">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="logoMain">
                                    <a href="#"><img src={Logo} alt="logo-image" /></a>
                                </div>
                                <div className="credTop">
                                {/* <Link to={nameBasedRoutes.login.path}>Login</Link>
                                <Link to={nameBasedRoutes.register.path}>Register</Link> */}
                                    <Link to={nameBasedRoutes.login.path}className="btn loginBtn fs-13"><div><span>Login</span><span>Login</span></div></Link>
                                    <Link to={nameBasedRoutes.register.path} className="btn registerBtn fs-13 ms-2"><div><span>Register</span><span>Register</span></div></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="promotionTop">
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-7 col-md-6">
                                    <div className="leftPromoTop">
                                        <span><h3>AI-Driven Business Match Making</h3></span>
                                        {/* <span><h3>AI-Driven Connections: Bridging Businesses to Buyers</h3></span>
                                        <span><h3>Your Business Exchange Hub: Seamless Connections, AI-Powered Success</h3></span> */}
                                        <p>Seamlessly link sellers and buyers through advanced AI-matching technology allowing you to bypass brokers and sales representatives. Buyers access the freshest businesses, while sellers enjoy high-quality leads at the industry’s lowest prices</p>
                                        <span className="leftPromoTopLink">
                                            <a target="_blank" href="https://apps.apple.com/us/app/bizlinx-ai/id6470197096"><img src={AppStore} alt="app-store-link" /></a>
                                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bizlinx.app"><img src={PlayStore} alt="play-store-link" /></a>
                                        </span>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-5 col-md-6 order-sm-1">
                                    <div className="rightPromoTop">
                                        <div className="rightPromoTopImg">
                                            <img src={PromoPhone} alt="promotional-phone-display" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="promoSTwo">

                    <div className="container max-container">
                        <div className="promoSTwoInner">
                            <div className="topArea text-center">
                                <h1>Solutions for Sellers</h1>
                                <p>Our membership-based approach ensures complete transparency for sellers, putting an end to the pay-per-lead scams and repurposed leads that can plague the industry.</p>
                            </div>
                            <div className="bottomArea">
                                <span className="imgMobile">
                                    <img src={PromoMobile} alt="mobile-view-image" />
                                </span>
                                <div className="partialColumns">
                                    <div className="row">
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Promote your franchise or existing business for sale for just $1 per month!</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Connect directly with buyers via email</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Receive real-time lead delivery and tracking</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Manage your ad, including videos and links to your best stories</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Exclusively targeting sustainable, innovative & emerging brands</span>
                                            </div>
                                        </div>
                                        {/* <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Update your ad in real-time, keeping it fresh and effective</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Upload videos and content directly to the app</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Enjoy the flexibility to cancel or pause advertising at any time</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Automate payments by franchisors, sellers, and buyers</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Seamlessly integrate with QuickBooks for accounting</span>
                                            </div>
                                        </div>
                                        <div className="col-12 col-sm-6 col-md-4">
                                            <div className="partialContent">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                    <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                                </svg>
                                                <span>Benefit from the expertise of a seasoned industry expert with 20 years of franchise development experience</span>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                <section className="promoSthree">
                    <div className="bottomImgSec">
                        <span>
                            <img src={ScreenBottom} alt="system-screen" />
                        </span>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-6 mw-700">
                                <div className="verticalData">
                                    <h2>Solutions for Buyers</h2>
                                    <p>We bring you pre-vetted franchises and resale opportunities, all in one simple and convenient app.</p>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                            </svg>
                                            <span>Pay zero fees as a buyer</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                            </svg>
                                            <span>Leverage AI matching technology to find the perfect business for you and your future</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                            </svg>
                                            <span>Study and research thousands of franchises on one platform – MLS for franchises and existing businesses</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20.604" height="15.365" viewBox="0 0 20.604 15.365">
                                                <path id="Icon_awesome-check" data-name="Icon awesome-check" d="M7,19.64l-6.7-6.7a1.03,1.03,0,0,1,0-1.457L1.759,10.03a1.03,1.03,0,0,1,1.457,0l4.511,4.511,9.662-9.662a1.03,1.03,0,0,1,1.457,0L20.3,6.336a1.03,1.03,0,0,1,0,1.457L8.455,19.64A1.03,1.03,0,0,1,7,19.64Z" transform="translate(0 -4.577)" fill="#fff" opacity="0.5" />
                                            </svg>
                                            <span>Keep your contact information private (we don’t sell your information or repurpose leads)</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bottomStore">
                    <div className="container">
                        <h3>Download The App Now</h3>
                        <div className="bottomStoreInner">
                            <a target="_blank" href="https://apps.apple.com/us/app/bizlinx-ai/id6470197096"><img src={AppStoreBlack} alt="app-store-image" /></a>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.bizlinx.app"><img src={PlayStoreBlack} alt="play-store-image" /></a>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <h3>Follow Us</h3>
                        <div className="bottomStoreInner socialMain">
                            <a target="_blank" href="https://www.instagram.com/bizlinx.ai/"><img src={InstagramLogo} alt="instagram-image" /></a>
                            <a target="_blank" href="https://www.youtube.com/watch?v=37u86RW1Hw0"><img src={YoutubeLogo} alt="youtube-image" /></a>
                        </div>
                    </div>
                </section>

                <footer className="footerMain">
                    <div className="container">
                        <div className="copyrightMain">
                            <p>Copyrights {yeatStr} All Rights Reserved</p>
                        </div>
                    </div>

                </footer>



            </div>
    )
}

