import { Button, Modal, Stack } from "react-bootstrap";

const DeleteModal = ({ show, handleClose, setDeletestatus, recordDelete }) => {
  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
      centered={true}
      scrollable={true}
      size="sm"
      className="theme-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="fs-16 fw-semibold">Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pb-2">
        <div className="fs-14 fw-semibold pb-2 mb-1">
          Are you sure want to delete this entry?
        </div>
      </Modal.Body>
      <Modal.Footer className="pt-0 border-0">
        <Stack direction="horizontal" gap={3} className="flex-wrap pb-2">
          <a
            class="btn btn-outline-secondary rounded-lg fs-15 mw-84 greyBtn"
            onClick={handleClose}
          >
            Cancel
          </a>
          <Button
            variant="primary"
            className="fs-15 text-white mw-84"
            onClick={recordDelete}
          >
            Confirm
          </Button>
        </Stack>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
