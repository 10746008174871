import Form from "react-bootstrap/Form";
import TextField from "@mui/material/TextField";

/**
 * Input Type Text Reusable Component
 * @date 4/13/2023 - 1:57:35 PM
 *
 * @param {{ controlId: any; label: any; placeholder: any; handleChange: any; handleBlur: any; errorsField: any; touched: any; value: any; rightIcon: any; size: any; name: any; isPasswordHintVisible: any; }} {
    controlId, label, placeholder, handleChange, handleBlur, errorsField, touched, value, rightIcon, size, name, isPasswordHintVisible
}
 * @returns
 */

const InputText = ({
  controlId,
  type,
  label,
  disabled,
  placeholder,
  handleChange,
  handleBlur,
  errorsField,
  touched,
  value,
  rightIcon,
  size,
  name,
  isPasswordHintVisible,
  className,
  ...rest
}) => {
  return (
    <>
      <div
        className={`position-relative ${rightIcon ? "form-right-icon" : ""}`}
      >
        <TextField
          onBlur={handleBlur}
          placeholder={placeholder}
          onChange={handleChange}
          type={type}
          error={errorsField && touched}
          label={label}
          variant="standard"
          helperText={errorsField && touched ? errorsField : ""}
          name={controlId}
          // readOnly={readOnly}
          disabled={disabled}
          inputProps={{ rest }}
          className={className}
          value={value}
          {...rest}
        />
        {rightIcon ? (
          <span
            className={`align-items-center d-flex end-0 justify-content-center pe-none position-absolute px-3 text-light top-0 ${
              size ? "h-35" : "h-40"
            }`}
          >
            {rightIcon}
          </span>
        ) : null}
        <Form.Control.Feedback type="invalid">
          {errorsField}
        </Form.Control.Feedback>
      </div>
    </>
  );
};

export default InputText;
