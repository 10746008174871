import React, { useEffect, useState } from "react";
import { Col, Form, Row, Card, Stack } from "react-bootstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Image } from "react-bootstrap";
import Logo from "../../../assets/images/logo.svg";
import {
  getLeadDetails,
  getPaymentDetails,
} from "../../../services/franchiseService";
import { toast } from "react-toastify";
import { nameBasedProtectedRoutes } from "../../../router/protected";
import Loader from "../../../components/common/Loader";
import CustomView from "../../../components/common/CustomView";
import { formatNumberWithTwoDecimals } from "../../../helpers/helpersMethods";

export const View = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    if (id) {
      setLoading(true);
      getPaymentDetails(id).then(
        (resp) => {
          const res = resp?.data;
          setData(res);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        },
        (error) => {
          const err = error?.response?.data;
          setLoading(false);
          if (err?.statusCode === 400) {
            toast.error(err.msg);
            navigate(nameBasedProtectedRoutes.paymentList.path);
          }
        }
      );
    }
  }, [id]);

  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100 overflow-auto">
        <div className="flex-grow-1 pageContent position-relative pt-4">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27.327"
                      height="23.423"
                      viewBox="0 0 27.327 23.423"
                    >
                      <path
                        id="Icon_material-history"
                        data-name="Icon material-history"
                        d="M17.115,4.5A11.713,11.713,0,0,0,5.4,16.212H1.5l5.062,5.062.091.182,5.257-5.244h-3.9a9.153,9.153,0,1,1,2.681,6.428L8.839,24.488A11.709,11.709,0,1,0,17.115,4.5Zm-1.3,6.506v6.506l5.569,3.305.937-1.575-4.555-2.707v-5.53Z"
                        transform="translate(-1.5 -4.5)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Payment History Details
                </h5>
                <Link
                  to={nameBasedProtectedRoutes.paymentList.path}
                  className="btn btn-primary mw-84"
                >
                  Back
                </Link>
              </Stack>
            </div>

            {!loading && data ? (
              <div className="franchiseDtl">
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Business / Franchise Name"
                      value={
                        data.franchise_business_type == "franchise"
                          ? data.franchise
                            ? data.franchise.name
                            : ""
                          : data.seller
                          ? data.seller.name
                          : ""
                      }
                      type="text"
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Type"
                      value={data.franchise_business_type}
                      type="text"
                      capital={true}
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Amount($)"
                      value={
                        data.price > 0
                          ? formatNumberWithTwoDecimals(data.price / 100)
                          : data.price
                      }
                      type="text"
                    />
                  </Col>
                </Row>
                <Row className="py-4 border-grey">
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Payment Date"
                      value={data.payment_date}
                      type="date"
                    />
                  </Col>
                  <Col sm={6} lg={4} xl={4}>
                    <CustomView
                      label="Payment Status"
                      value={data.is_payment_success}
                      type="text"
                      capital={true}
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              ""
            )}
            <Loader isLoading={loading} />
          </Card>
        </div>
      </div>
    </>
  );
};
