import { Route, Routes } from 'react-router-dom';
import storage from '../../helpers/storage';
import { AddFranchise } from './Add/AddFranchise';
import { EditFranchise } from './Edit/Edit';
import { AllFranchise } from './List/List';
import { View } from './View/View';


export const Franchise = () =>
{
    storage.setPageTitle('Franchise');
    return (  
        <Routes>
            <Route path="/" element={<AllFranchise />} />
            <Route path="/add" element={<AddFranchise />} />
            <Route path="/edit/:id" element={<EditFranchise />} />
            <Route path="/view/:id" element={<View />} />
        </Routes>
    )
};