import React, { useEffect, useState } from "react";
import { Card, Col, Row, Stack } from "react-bootstrap";
import { ActionCell } from "../../../components/common/dataGrid/ActionCell";
import { Box, TextField } from "@mui/material";
import CustomSelect from "../../../components/common/CustomSelect";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  getUserListAPI,
  changeUserStatus,
  deleteUser,
} from "../../../services/userService";
import { toast } from "react-toastify";
import Loader from "../../../components/common/Loader";
import Cookies from "universal-cookie";
import {
  getLeadList,
  updateLeadStatus,
} from "../../../services/franchiseService";
import { DataGrid } from "@mui/x-data-grid";
import CustomPagination from "../../../components/common/dataGrid/CustomPagination";
import "../../../components/common/dataGrid/datagrid.scss";
import { Link, useNavigate } from "react-router-dom";
import { nameBasedProtectedRoutes } from "../../../router/protected";

import { CapitalText, truncateText } from "../../../helpers/helpersMethods";
import commonValue from "../../../constants/commonValue";
import moment from "moment";
import { styled } from "@mui/material/styles";
import { SelectCell } from "../../../components/common/SelectCell";
import FormDatesPicker from "../../../components/common/FormDatesPicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { MdClose } from "react-icons/md";
const customTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
    htmlFontSize: 17,
  },
  palette: {
    primary: {
      main: "#17B0B2",
    },
    text: {
      primary: "#000000",
      secondary: "#777777",
    },
  },
});

export const AllLead = () => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [loading, setLoading] = useState(true);
  const [userStatus, setUserStatus] = useState();
  const [data, setData] = useState([]);
  const [mutation, setMutation] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [totalReults, setTotalResults] = useState(0);
  const [sorting, setSorting] = useState([]);
  const cookies = new Cookies();
  const [deletestatus, setDeletestatus] = useState(false);
  const [deleteRecordId, setDeleteRecordId] = useState();
  const [show, setShow] = useState(false);
  const [selectValue, setSelectValue] = useState("");
  const [selectRow, setSelectRow] = useState("");
  const [selectDate, setSelectDate] = useState(null);

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Found</Box>
      </StyledGridOverlay>
    );
  }
  useEffect(() => {
    if (selectValue) {
      const requestData = {
        id: selectRow,
        status: selectValue,
      };

      setLoading(true); // Show loader during API call

      // Make the API call to update the status
      updateLeadStatus(requestData).then(
        (response) => {
          const res = response?.data;
          setTimeout(() => {
            setLoading(false);
            if (response?.statusCode === 200) {
              // Update the data in your data grid
              const updatedData = data.map((item) => {
                if (item.id === selectRow) {
                  return { ...item, status: selectValue };
                }
                return item;
              });
              setData(updatedData);
            }
          }, 1000);
        },
        (error) => {
          setLoading(false);
          // Handle error and show a notification
          toast.error(error.response.data.msg);
        }
      );
    }
  }, [selectValue, selectRow]);

  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      editable: false,
      flex: 0.05,
      minWidth: 150,
    },
    {
      field: "created_at",
      headerName: "Generate Date",
      editable: false,
      flex: 0.6,
      minWidth: 200,
      maxWidth: 200,
      renderCell: (props) =>
        moment(props.row.created_at).format(
          commonValue.commaSeparatedShowDateTime
        ),
    },
    {
      field: "id",
      headerName: "Business / Franchise Name",
      editable: false,
      flex: 0.6,
      minWidth: 200,
      maxWidth: 300,
      renderCell: (props) =>
        truncateText(
          props.row.franchise_business_type == "franchise"
            ? props.row.franchise
              ? props.row.franchise.name
              : ""
            : props.row.seller
            ? props.row.seller.name
            : ""
        ),
    },
    // {
    //   field: "message",
    //   headerName: "Message",
    //   editable: false,
    //   flex: 0.6,
    //   minWidth: 200,
    //   maxWidth: 300,
    //   renderCell: (props) => truncateText(props.row.message),
    // },
    {
      field: "franchise_business_type",
      headerName: "Type",
      editable: false,
      flex: 0.6,
      minWidth: 200,
      maxWidth: 200,
      renderCell: (props) =>
        truncateText(CapitalText(props.row.franchise_business_type)),
    },

    {
      field: "status",
      headerName: "Current Status",
      editable: false,
      flex: 0.3,
      minWidth: 200,
      sortable: false,
      renderCell: (props) => {
        return (
          <SelectCell
            defaultChecked={props.row.status}
            id={props.row.id}
            selectedRowId={selectRow}
            onSelectRow={handleSelectRow}
            value={props.row.status}
            className="customSelectT"
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      editable: false,
      flex: 0.2,
      minWidth: 120,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (props) => {
        return (
          <ActionCell
            controlId="app/lead"
            isViewEnable={true}
            isEditEnable={false}
            isDeleteEnable={false}
            redirectID={props.row.id}
            rowData={props.row}
          />
        );
      },
    },
  ];

  // Searching and listing user list
  // const handleValueChange = (value, type) => {
  //   setSelectedValue(value);
  // };

  useEffect(() => {
    setCurrentPage(1);

    fetchList({
      page: currentPage,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchtext: selectedValue,
      searchByDate: selectDate ? dayjs(selectDate).format("YYYY-MM-DD") : "",
    });
  }, [selectDate]);
  useEffect(() => {
    setCurrentPage(1);

    fetchList({
      page: currentPage,
      limit: rowPerPage,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchtext: selectedValue,
      searchByDate: selectDate ? dayjs(selectDate).format("YYYY-MM-DD") : "",
    });
  }, [selectedValue]);

  // Get user list details
  const fetchList = (params) => {
    toast.dismiss();
    setLoading(true);
    getLeadList({ ...params }).then(
      (response) => {
        console.log(response);
        const res = response?.data;
        const itemsPerPage = params?.limit || 10;
        const currentPage = params?.page || 1;
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        const dataWithSNo = res.record.map((item, index) => ({
          ...item,
          sNo: startIndex + index,
        }));
        setLoading(false);
        setData(dataWithSNo);
        setTotalPages(res.totalPages);
        setTotalResults(res.totalResults);
        setLoading(false);
      },
      (error) => {
        const err = error?.response?.data;
        setLoading(false);
        if (err?.statusCode === 400) {
          toast.error(err.msg);
        }
      }
    );
  };

  // On page load fetch user list
  useEffect(() => {
    fetchList({
      page: currentPage,
      limit: rowPerPage,
      searchtext: selectedValue,
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      searchByDate: selectDate ? dayjs(selectDate).format("YYYY-MM-DD") : "",
    });
  }, [page, rowPerPage, sortColumn, sortDirection, currentPage]);

  const handlePageChange = (params) => {
    setCurrentPage(params.page + 1);
    setRowPerPage(params.pageSize);
  };
  const handlePageSizeChange = (params) => {
    setRowPerPage(params.pageSize);
  };
  //change status
  const handleSelectRow = (id, value) => {
    setSelectRow(id);
    setSelectValue(value);
  };
  const handleClear = () => {
    setSelectDate(null);
  };
  return (
    <>
      <div className="d-flex flex-column pageContainer p-3 h-100">
        <div className="flex-grow-1 h-100 pageContent position-relative pt-4 overflow-auto">
          <Card className="h-100 bg-white shadow border-0 theme-card-cover">
            <div className="theme-card-header px-1">
              <Stack
                direction="horizontal"
                gap={2}
                className="flex-wrap justify-content-between px-3 pt-3 pb-2 align-items-start"
              >
                <h5 className="mb-0 position-relative fs-18 text-black fw-medium">
                  <div className="align-items-center bg-primary d-inline-flex h-60 justify-content-center  position-absolute rounded start-0 text-white theme-icon-box w-60 z-1">
                    <svg
                      id="Job_search"
                      data-name="Job search"
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.748"
                      height="31.748"
                      viewBox="0 0 31.748 31.748"
                    >
                      <g id="Job_Search-2" data-name="Job_Search">
                        <g
                          id="Group_21747"
                          data-name="Group 21747"
                          transform="translate(0 3.72)"
                        >
                          <path
                            id="Path_28727"
                            data-name="Path 28727"
                            d="M13.825,68.789,8.789,73.825h5.036Z"
                            transform="translate(-8.244 -68.244)"
                            fill="#fff"
                          />
                          <path
                            id="Path_28728"
                            data-name="Path 28728"
                            d="M19.532,74.882A10.213,10.213,0,0,1,10.431,60H7.441v7.441H0V88.028H20.463V74.835C20.156,74.863,19.846,74.882,19.532,74.882ZM3.72,71.161h7.441v1.86H3.72ZM16.742,84.183H3.72v-1.86H16.742Zm0-3.72H3.72V78.6H16.742Zm0-3.72H3.72v-1.86H16.742Z"
                            transform="translate(0 -60)"
                            fill="#fff"
                          />
                        </g>
                        <g
                          id="Group_21748"
                          data-name="Group 21748"
                          transform="translate(11.161)"
                        >
                          <circle
                            id="Ellipse_18"
                            data-name="Ellipse 18"
                            cx="0.93"
                            cy="0.93"
                            r="0.93"
                            transform="translate(7.441 5.581)"
                            fill="#fff"
                          />
                          <path
                            id="Path_28729"
                            data-name="Path 28729"
                            d="M275.581,182.79a2.79,2.79,0,1,0-5.581,0v2.278a7.852,7.852,0,0,0,5.581,0Z"
                            transform="translate(-264.419 -168.839)"
                            fill="#fff"
                          />
                          <path
                            id="Path_28730"
                            data-name="Path 28730"
                            d="M188.371,0a8.369,8.369,0,0,0-4.651,15.329V13.952A4.656,4.656,0,0,1,188.371,9.3a2.79,2.79,0,1,1,2.79-2.79,2.793,2.793,0,0,1-2.79,2.79,4.656,4.656,0,0,1,4.651,4.651v1.377A8.369,8.369,0,0,0,188.371,0Z"
                            transform="translate(-180)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <path
                        id="Path_28731"
                        data-name="Path 28731"
                        d="M421.749,240.377a10.236,10.236,0,0,1-1.312,1.319l4.362,4.548,1.315-1.315Z"
                        transform="translate(-394.367 -225.472)"
                        fill="#fff"
                      />
                    </svg>
                  </div>
                  Leads List
                </h5>
                {/* <Link
                  to={nameBasedProtectedRoutes.sellerAdd.path}
                  className="btn btn-primary"
                >
                  Add New
                </Link> */}

                {/* <Button variant="primary">Add Franchise</Button> */}
              </Stack>
            </div>
            <div className="flex-grow-1 d-flex flex-column px-3 h-100 overflow-auto">
              <div className="theme-data-table-filter px-1">
                <ThemeProvider theme={customTheme}>
                  <Row className="justify-content-end">
                    <Col
                      sm={5}
                      lg={2}
                      className="mb-3 pb-1 dateTopPanel position-relative"
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {selectDate && (
                          <div
                            className="close-icon-calendar"
                            onClick={handleClear}
                          >
                            <MdClose size={21} color="#7c7a7a" />
                          </div>
                        )}
                        <DemoContainer components={["DatePicker"]}>
                          <DatePicker
                            label="Select Date"
                            className="custom-datepicker custom-text-field"
                            value={selectDate}
                            onChange={(e) => {
                              setSelectDate(e);
                            }}
                            maxDate={dayjs().subtract(0, "day")}
                            format="DD-MM-YYYY"
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Col>
                    <Col
                      sm={5}
                      lg={2}
                      className="mb-3 pb-1 searchTopPanel d-flex align-items-end"
                    >
                      <TextField
                        id="search-filter"
                        className="custom-text-field"
                        label="Search"
                        variant="standard"
                        fullWidth
                        value={selectedValue}
                        onChange={(e) => {
                          setSelectedValue(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </ThemeProvider>
              </div>

              {
                <div className="dataGridMain flex-fill px-1 w-100">
                  <DataGrid
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    rows={data}
                    columns={columns}
                    pageSize={rowPerPage}
                    rowCount={totalReults}
                    onPageChange={handlePageChange}
                    onPageSizeChange={handlePageSizeChange}
                    onSortModelChange={(model) => {
                      // Extract the sorting information from the model
                      const sortField = model[0]?.field || "id";
                      const sortDirection = model[0]?.sort || "asc";
                      setSortColumn(sortField);
                      setSortDirection(sortDirection);
                      // Update the state and fetch new data based on the sorting
                      setSorting(model);
                      fetchList({
                        page,
                        limit: rowPerPage,
                        searchtext: selectedValue,
                        sortColumn: sortColumn,
                        sortDirection: sortDirection,
                      });
                    }}
                    pageSizeOptions={[5, 10, 15, 20]}
                    initialState={{
                      pagination: {
                        paginationModel: {
                          page: currentPage - 1,
                          pageSize: rowPerPage,
                        },
                      },
                    }}
                    paginationMode="server"
                    onPaginationModelChange={handlePageChange}
                    disableColumnMenu
                  />
                </div>
              }
            </div>
          </Card>
        </div>
      </div>
      <Loader isLoading={loading} />
    </>
  );
};
