import * as React from "react";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { FormHelperText } from "@mui/material";

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
const FormDatesPicker = ({
  placeholder,
  label,
  handleChange,
  name,
  setFieldValue,
  value,
  errorsField,
  controlId,
  allowPastDates,
  allowFutureDates,
  leftColSmall,
}) => {
  const minDate = allowPastDates ? null : dayjs().add(1, "day");
  const maxDate = allowFutureDates ? null : dayjs().subtract(1, "day");

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          onChange={(event) => {
            const dates = dayjs(event, "YYYY-MM-DD");
            setFieldValue(name, dates);
          }}
          className="form-control"
          slotProps={{ textField: { placeholder: placeholder } }}
          value={value ? dayjs(value) : null}
          minDate={minDate} // Set the minimum allowed date
          maxDate={maxDate} // Set the maximum allowed date
          format="DD/MM/YYYY"
          showToolbarPlaceholder
        />
      </LocalizationProvider>
      <FormHelperText className="MuiFormHelperText-root red">
        {errorsField}
      </FormHelperText>
    </>
  );
};

export default FormDatesPicker;
